// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
// Config
import { BackendURL } from "../../../Config";
// cookies
import Cookies from 'js-cookie';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// componentes
import Layout from "../../Layout/Layout"

// custom css
// import './EliminarCuenta.css';

export default function PoliticaPrivacidad() {
    return (
        <Layout>
            <div className="PoliticaPrivacidad">
                <h1> Politica de privacidad de MendoCann </h1>

                <p>
                Última actualización: "25/04/2024"
                </p>
                <p>
                    Esta Política de Privacidad describe cómo "INGcome" ("nosotros", "nuestro" o "nos") recopila, utiliza y comparte información personal cuando utilizas nuestra aplicación móvil "MendoCann" (en adelante, "la Aplicación"). Al utilizar la Aplicación, aceptas las prácticas descritas en esta política.
                </p>

                <h2> Información que Recopilamos </h2>
                <p>
                Cuando utilizas la Aplicación, podemos recopilar cierta información personal que nos proporcionas voluntariamente, como tus likes y dislikes.
                </p>

                <h2> Uso de la Información </h2>
                <p>
                Utilizamos la información que recopilamos para proporcionar y mantener la funcionalidad de la Aplicación y para personalizar tu experiencia dentro de la Aplicación y para mejorar nuestros servicios.
                </p>

                <h2> Compartir de la Información </h2>
                <p>
                No compartimos tu información personal con terceros, excepto en las siguientes circunstancias:
                </p>

                <ul>
                    <li> Cuando sea necesario para cumplir con la ley o proteger nuestros derechos legales. </li>
                    <li> En caso de una transacción corporativa, como una fusión, adquisición o venta de activos, donde tu información personal pueda ser transferida como parte de los activos comerciales. </li>
                </ul>

                <h2> Seguridad de la Información </h2>
                <p>
                Nos esforzamos por mantener la seguridad de tu información personal y hemos implementado medidas de seguridad razonables para protegerla contra accesos no autorizados, uso indebido o divulgación.
                </p>

                <h2> Cambios en esta Política de Privacidad </h2>
                <p>
                Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cualquier cambio mediante la publicación de la Política de Privacidad actualizada en la Aplicación. Te recomendamos que revises periódicamente esta Política de Privacidad para estar al tanto de cualquier cambio.
                </p>

                <h2> Contacto </h2>
                <p>
                    Si tienes alguna pregunta sobre esta Política de Privacidad, contáctanos a "contacto@ing-come.com".
                </p>
            </div>
        </Layout>
    )
}