// React
import { createContext, useState, useEffect } from "react"
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// icons
import CloseIcon from '@mui/icons-material/Close';

export default function Campo({ id, register, setCampos_count, Campos_count }) {

    const [items, SetItems] = useState([]);

    const [Input, setInput] = useState();

    const [type, SetType] = useState('');

    const handleChange = (event) => {
        SetType(event.target.value);
        register(`info_extra.${id}.tipo`, { value: event.target.value })
    };

    const onChange = (e) => {
        setInput(e)
    }

    const addItem = () => {
        SetItems(items => [...items, Input ]);
        setInput('')
    }

    useEffect(() => {
        for (let index = 0; index < items.length; index++) {
            register(`info_extra.${id}.valores.${index}`, { value: items[index] } )
            
        }
    }, [items]);


    return (
        <div className="formulario_entradas campo">
            <button type="button" className='DeletButton' onClick={() => setCampos_count(Campos_count - 1)}>
                Eliminar Entrada
                <CloseIcon />
            </button>
            <div className="data">
                <FormControl>
                    <InputLabel
                        id="demo-simple-select-label"
                        color="secondary"
                    >
                        Tipo de campo
                    </InputLabel>
                    <Select
                        className="TipoCampo"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Tipo de campo"
                        color="secondary"
                        onChange={handleChange}
                    >
                        <MenuItem value={"Texto"}>Texto</MenuItem>
                        <MenuItem value={"Seleccion"}>Seleccion</MenuItem>
                    </Select>
                </FormControl>

                {type === "Texto" ?
                    <TextField
                        required
                        id="outlined-required"
                        color="secondary"
                        label="Nombre del campo"
                        {...register(`info_extra.${id}.nombre`)}
                    />
                    : null}

                {type === "Seleccion" ?
                    <>
                        <TextField
                            required
                            id="outlined-required"
                            color="secondary"
                            label="Nombre del campo"
                            {...register(`info_extra.${id}.nombre`)}
                        />
                        <TextField
                            id="outlined-required"
                            color="secondary"
                            label="item"
                            value={Input}
                            onChange={e => onChange(e.target.value)}
                        />

                        <Button
                            type='button'
                            variant="contained"
                            color="secondary"
                            endIcon={<SendIcon />}
                            onClick={() => addItem()}
                        >
                            Añadir item
                        </Button>
                    </>
                    : null}
            </div>

            {type === "Seleccion" ?
                <div className="items">
                    <h3>Items</h3>
                    <div className="itemsContainer">
                        {items.map((item) => <span> {item} </span>)}
                    </div>
                </div>
                : null}


        </div>
    )
}