// MUI
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Configuracion({ register }) {
    return (

        <FormGroup>
            <h3> Configuracion de pantalla </h3>
            <FormControlLabel control={<Checkbox defaultChecked {...register(`configuracion.numero_entradas_restantes`)} color="secondary" />} label="Mostrar el numero de entradas disponibles" />
            <FormControlLabel control={<Checkbox defaultChecked {...register(`configuracion.promiciones`)} color="secondary" />} label="Mostrar promociones para afiliados" />
        </FormGroup>
    )
}
