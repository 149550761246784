// Config
// import {BackendURL} from "../../../Config";
// MUI
import Button from '@mui/material/Button';
// css
import './EventCard.css';
// IMGs
import kk from './img/65f0da8e392211969739815c.jpeg'

// export default function EventCard({ evento, userID }) {
export default function EventCard({ evento }) {
    return (
        <a href={'/evento/' + evento.ID_evento} className='EventCard'>
            {/* <div className='imgs'>
                <div className='imgs falsofondo'>
                    <img src={`https://sisabackend.herokuapp.com/imgs/${evento.ID_evento}.png`} alt="" />
                </div>
                <img src={`https://sisabackend.herokuapp.com/imgs/${evento.ID_evento}.png`} className='fondo' alt="" />
            </div> */}

            <div className='imgs'>
                <div className='imgs falsofondo'>
                    {/* <img src={BackendURL(`/utilities/img/${evento.event_media}`)} alt="" /> */}
                    <img src={kk} alt="portada del evento 2" />
                </div>
                {/* <img src={BackendURL(`/utilities/img/${evento.event_media}`)} className='fondo' alt="" /> */}
                <img src={kk} className='fondo' alt="portada del evento" />
            </div>

            <div className='texto'>
                <h3> {evento.nombre_evento} </h3>

                <small className='fecha'> {evento.fecha_inicio}, {evento.hora_inicio} </small>
                <small> {evento.event_location_name} </small>
            </div>



            {/* {
                evento.estado_pago != 'approved' ? <small> Estado del pago: {evento.estado_pago} </small> : null
            } */}

            {/* {evento.estado_pago == 'approved' ?
                <a href={`/entrada/${evento.ID_evento}/${userID}/${evento.fecha_inico}`}>
                    <Button
                        color="secondary"
                        className='descargar'
                    > Entrada </Button>
                </a>
                :
                <a href=''>
                    <Button
                        color="secondary"
                        className='descargar'
                        disabled
                    > Entrada </Button>
                </a>
            } */}

        </a>
    )
}