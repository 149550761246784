// CSS
import "./PropValor.css"
// IMGs
import mayor_control from "./imgs/globo item 1.png"
import automatizacion from "./imgs/globo item 2.png"
import integracion from "./imgs/globo item 3.png"
import personalizacion from "./imgs/globo item 4.png"
import ondas from "./imgs/ONDAS.svg"


export default function PropValor() {
    return (
        <section id="propvalor">

            <div className="izquierda">
                <div className="texto">
                    <h2> PROPUESTA DE VALOR </h2>
                    <div className="linea"></div>
                </div>
                <h3> ¿Cómo aportamos valor a tu evento? </h3>
                <p> 
                    SISA es una plataforma online de gestión de eventos que ofrece un <b> seguimiento integral </b> de los asistentes. 
                    Los organizadores pueden <b> crear eventos </b>, cargar cronogramas y actividades, <b> tomar asistencia </b>, <b> gestionar pagos </b>, 
                    y ofrecer un <b> servicio de tarjetas NFC integradas </b> para acreditar la asistencia a las diferentes actividades del evento.</p>
            </div>

            <div className="derecha">
                <div className="card">
                    <div className="img">
                        <img src={mayor_control} alt="" />
                    </div>
                    <div className="texto">
                    <h4> Mayor <span className="morado"> control y seguimiento: </span> </h4>
                    <p> Ofrecemos a los organizadores de eventos la posibilidad de realizar un seguimiento integral de los asistentes, desde el proceso de inscripción hasta la acreditación en el evento. </p>
                    </div>
                </div>
                <div className="card">
                    <div className="img">
                        <img src={automatizacion} alt="" />
                    </div>
                    <div className="texto">
                    <h4> <span className="azul"> Automatización </span> de procesos: </h4>
                    <p> Contribuimos a los organizadores a automatizar muchos procesos de gestión de eventos, como la acreditación y toma de asistencia, lo que reduce la carga de trabajo y permite a los organizadores centrarse en otros aspectos importantes del evento. </p>
                    </div>
                </div>
                <div className="card">
                    <div className="img">
                        <img src={integracion} alt="" />
                    </div>
                    <div className="texto">
                    <h4> <span className="celeste"> Integración </span> de tecnología: </h4>
                    <p> Ofrecemos un servicio de tarjetas NFC integradas con el sistema, como así también el uso de QR’s, lo que permite a los asistentes acreditar su asistencia a las diferentes actividades de forma más eficiente. </p>
                    </div>
                </div>
                <div className="card">
                    <div className="img">
                        <img src={personalizacion} alt="" />
                    </div>
                    <div className="texto">
                    <h4> Personalización: </h4>
                    <p> Brindamos a los organizadores la capacidad de personalizar la plataforma según las necesidades específicas de su evento, lo que les brinda un mayor control y flexibilidad en la organización del evento. </p>
                    </div>
                </div>
            </div>

            <img src={ondas} alt="backgraund" className="fondo"/>


        </section>
    )
}