// React
import { useEffect, useState } from 'react';
// MUI
import TextField from '@mui/material/TextField';
// icons
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

export default function Entrada({ setValue, register, entrada_info }) {
    const [OnDate, SetOnDate] = useState(false);
    const [FechaInicio, SetFechaInicio] = useState('kk');
    const [FechaFinal, SetFechaFinal] = useState('');

    useEffect(() => {
        const fecha_inicio = entrada_info.fecha_inicio.split("-")
        const hora_inicio = entrada_info.hora_inicio.split(":")

        const fecha_final = entrada_info.fecha_final.split("-")
        const hora_final = entrada_info.hora_final.split(":")

        const InitDate = new Date(fecha_inicio[0], fecha_inicio[1] - 1, fecha_inicio[2], hora_inicio[0], hora_inicio[1])
        const FinalDate = new Date(fecha_final[0], fecha_final[1] - 1, fecha_final[2], hora_final[0], hora_final[1])
        const ActualDate = new Date()

        SetFechaInicio(InitDate.toLocaleDateString())
        SetFechaFinal(FinalDate.toLocaleDateString())


        if (InitDate.getTime() < ActualDate.getTime() && FinalDate.getTime() > ActualDate.getTime()) {
            SetOnDate(true)
        }

    }, []);

    register(`tickets.${entrada_info.nombre}.descuento`, { value: entrada_info.descuento })



    return (
        <div className='entrada'>
            <div className='data'>
                <h2> {entrada_info.nombre} </h2>
                <p {...register(`tickets.${entrada_info.nombre}.descripcion`, { value: entrada_info.Descripcion })}>
                    {entrada_info.Descripcion}
                </p>
                {/* <h3 {...register(`tickets.${entrada_info.nombre}.precio`, { value: entrada_info.precio })}> Precio: ARS ${entrada_info.precio} </h3> */}
                <h3 {...register(`tickets.${entrada_info.nombre}.precio`, { value: entrada_info.precio })}> Precio ${entrada_info.precio}  </h3>

                {entrada_info.descuento ?
                    <>
                        <small>
                            Los descuentos se aplican automáticamente sobre el total al seleccionar la entrada.
                        </small>
                        <br />
                    </>
                    :
                    <>
                        <small>
                            No se aplican descuentos a esta entrada
                        </small>
                        <br />
                    </>
                }



                <small>
                    Disponible del {FechaInicio} a las {entrada_info.hora_inicio} hasta el {FechaFinal} a las {entrada_info.hora_final}
                </small>
            </div>

            {OnDate ? <CantidadEntradas setValue={setValue} register={register} entrada_info={entrada_info} /> : null}

        </div>
    )
}


function CantidadEntradas({ setValue, register, entrada_info }) {

    const [Cantidad, SetCantidad] = useState(0);

    useEffect(() => {
        setValue(`tickets.${entrada_info.nombre}.cantidad`, Cantidad)

        if (Cantidad > entrada_info.cantidad_maxima_compra) {
            SetCantidad(0)
        }
        if (Cantidad < 0) {
            SetCantidad(0)
        }

    }, [Cantidad]);

    const sumar = () => {
        SetCantidad(Cantidad + 1)
    };
    const restar = () => {
        SetCantidad(Cantidad - 1)
    };

    return (
        <div className='cantidad'>
            <button
                onClick={restar}
                type="button"
            >
                <ArrowBackIosOutlinedIcon />
            </button>
            <TextField
                id="outlined-number"
                label="Cantidad"
                type="number"
                color="secondary"
                InputLabelProps={{
                    shrink: true,
                }}
                value={Cantidad}
                onChange={(event) => {
                    SetCantidad(event.target.value);
                }}
                {...register(`tickets.${entrada_info.nombre}.cantidad`, { value: Cantidad })}
            />
            <button
                onClick={sumar}
                type="button"
            >
                <ArrowForwardIosOutlinedIcon />
            </button>
        </div>
    )
}