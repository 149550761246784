// React
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useForm } from 'react-hook-form';
// Config
import {BackendURL} from "../../Config";
// cookies
import Cookies from 'js-cookie';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// MUI
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
// icons
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

// componentes
import Layout from "../Layout/Layout"

// custom css
import './CodigosPromocionales.css';

export default function CodigosPromocionales() {

    // navigation
    const navigate = useNavigate();
    // id del evento traido de los parametros de la url
    const { id_evento, codigo } = useParams();
    // data del evento traida del backend
    const [Data, SetData] = useState();
    // Token traido de cookies
    const { decodedToken, isExpired } = useJwt(Cookies.get('Token'));
    // Data del usuario traida del token
    const [UserData, SetUserData] = useState();
    // creo el state "open" para guardar el estado del backdrop de informacion extra
    const [open, setOpen] = useState(false);
    // creo el state "Canjeado" para guardar el estado cupon
    const [Canjeado, SetCanjeado] = useState(false);
    // creo el state "Cupon" para guardar el estado cupon
    const [Cupon, SetCupon] = useState(false);
    // creo el state "ListaCanjeados" para guardar el estado cupon
    const [ListaCanjeados, SetListaCanjeados] = useState(false);
    // uso el "useForm()" hook
    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm()
    // Creo la constante "MySwal" con la funcion "withReactContent" y "Swal"
    const MySwal = withReactContent(Swal)

    // Reviso si existe el token, si no existe, redirecciono al usuario al Login
    useEffect(() => {
        if (!Cookies.get('Token')) {
            navigate(`/login/codigo-${id_evento}-${codigo}`)
        }
    }, []);

    useEffect(() => {
        if (Cookies.get('Token')) {
            // SetUserData(decodedToken)
        }
        if (decodedToken) {
            axios({
                method: 'get',
                url: BackendURL(`/users/info/${decodedToken.id}`),
                data: {
                    event_id: id_evento,
                },
                headers: {
                    "Accept": "application/json"
                },
            }).then(function (response) {
                console.log(response)
                SetUserData(response.data)
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [decodedToken]);

    // Pido al backend la informacion del evento
    useEffect(() => {
        axios({
            method: 'post',
            url: BackendURL(`/events/info`),
            data: {
                event_id: id_evento,
            },
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            console.log(response)
            SetData(response.data)

            response.data.tickets_promocionales.map((cupon) => {
                if (cupon.codigo === codigo) {
                    SetCanjeado(cupon.canjeado)
                    SetCupon(cupon)
                }
            })

            const codigosCangeados = response.data.tickets_promocionales.filter((code) => code.canjeado == true)

            SetListaCanjeados(codigosCangeados)

        }).catch(function (error) {
            console.log(error);
        });
    }, []);


    const onSubmit = (FormData) => {
        // lanzo la alerta de carga
        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })

        const data = {
            event_id: id_evento,
            user_id: decodedToken.id,
            user_name: UserData.nombre,
            codigo: codigo,
            nombre_evento: Data.event_name,
            fecha_inico: Data.fecha_inicio,
            info_extra: FormData.info_extra
        }


        // envio la data al backend
        axios({
            method: 'post',
            url: BackendURL('/events/canjear'),
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Tu codigo fue canjeado</strong>,
                html: <i>Podes ver mas informacion sobre el evento en tu perfll</i>,
                icon: 'success'
            })
        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }


    return (
        <Layout>
            <div id='canjearcodigos'>

                {/* {ListaCanjeados.length > 60 ?
                    <div className='title'>
                        <h1> El cupo de 50 se ha completado </h1>
                        <span> Quedate atento para nuevas promociones! </span>
                    </div>
                    :
                    Data ?
                        Canjeado ?
                            <div className='title'>
                                {UserData ? <h1> Este cupon ya fue canjeado </h1> : <Skeleton variant="rounded" width='80%' height="100px" />}
                                {Data ? <span> Este cupon fue canjeado por: {Cupon.canjeado_por.name} </span> : <Skeleton variant="rounded" width='80%' height="50px" />}
                            </div>
                            :
                            <div className='title'>
                                {UserData ? <h1> Felicitaciones {UserData.nombre}! </h1> : <Skeleton variant="rounded" width='80%' height="100px" />}
                                {Data ? <span> Recibiste un codigo promocinal para: {Data.event_name} </span> : <Skeleton variant="rounded" width='80%' height="50px" />}
                            </div>
                        :
                        <div className='title'>
                            <Skeleton variant="rounded" width='80%' height="100px" />
                            <Skeleton variant="rounded" width='80%' height="50px" />
                        </div>
                } */}

                 {Data ?
                    Canjeado ?
                        <div className='title'>
                            {UserData ? <h1> Este cupon ya fue canjeado </h1> : <Skeleton variant="rounded" width='80%' height="100px" />}
                            {Data ? <span> Este cupon fue canjeado por: {Cupon.canjeado_por.name} </span> : <Skeleton variant="rounded" width='80%' height="50px" />}
                        </div>
                        :
                        <div className='title'>
                            {UserData ? <h1> Felicitaciones {UserData.nombre}! </h1> : <Skeleton variant="rounded" width='80%' height="100px" />}
                            {Data ? <span> Recibiste un codigo promocinal para: {Data.event_name} </span> : <Skeleton variant="rounded" width='80%' height="50px" />}
                        </div>
                    :
                    <div className='title'>
                        <Skeleton variant="rounded" width='80%' height="100px" />
                        <Skeleton variant="rounded" width='80%' height="50px" />
                    </div>
                }
                
                {/* {ListaCanjeados.length > 60 ? null :  */}
                {Canjeado ? null
                    : Data ?
                        <>
                            <div className='canjear'>
                                <h2> haz click en "canjear" para canjear tu codigo, cada codigo solo puede ser canjeado una vez </h2>
                                <Button
                                    onClick={() => setOpen(true)}
                                    type='submit'
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<SendIcon />}>

                                    Canjear
                                </Button>
                            </div>

                            <form className='entradas' onSubmit={handleSubmit(onSubmit)}>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={open}
                                >
                                    <div className='backdrop'>
                                        <div className='cerrar_container'>
                                            <button className='cerrar' type='button' onClick={() => setOpen(false)}>
                                                cerrar
                                                <CloseIcon />
                                            </button>
                                        </div>
                                        <h1> Por favor completa este formulario antes de continuar  </h1>

                                        {Data ?
                                            <div className='CustomFomrElement'>
                                                {Data.info_extra.length != 0 ? Data.info_extra.map((info, index) =>
                                                    <FormControl sx={{ m: 1, width: '70%' }} key={index}>
                                                        <TextField
                                                            color="secondary"
                                                            fullWidth
                                                            required
                                                            id="outlined-required-nombre"
                                                            label={info.nombre}
                                                            {...register(`info_extra.${info.nombre.replace(/ /g, "").replace(/,/g, "")}}`)}
                                                        />
                                                    </FormControl>)
                                                    : null}
                                            </div>
                                            : null}

                                        {Data ? <Button className='pagar' color="secondary" variant="contained" type='submit' onSubmit={handleSubmit(onSubmit)}> Confirmar </Button> : null}

                                    </div>
                                </Backdrop>
                            </form>
                        </>
                    : <Skeleton variant="rounded" width='80%' height="300px" />
                }
            </div>

        </Layout>
    )
}