// React
import { useForm } from "react-hook-form";
// Config
import { BackendURL } from "../../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// componentes
import Direcion from "./sections/Direcion/Direcion";
import FechaHora from "./sections/FechaHora/FechaHora";
import InfoBasica from "./sections/InfoBasica";
// css
import "../CrearEventos.css"
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';



export default function CrearEvento_1({ EventInfo, setStatus }) {
    // form hooks
    const { register, unregister, handleSubmit, watch, formState: { errors } } = useForm();
    // alerts
    const MySwal = withReactContent(Swal)

    const onSubmit = data => {
        console.log(data)
        // agrego el token a la data que se envia al backend
        data.Token = Cookies.get('Token')
        data.EstadoCreacion = 2

        let url = ''

        if (Cookies.get('EventoEnCreacion_id')) {
            url = BackendURL('/events/save/general')
            data.id_evento = Cookies.get('EventoEnCreacion_id')
        } else {
            url = BackendURL('/events/save/inicio')
        }

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                "Accept": "application/json"
            },

        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tu usuario fue creado exitosamente!</i>,
                icon: 'success'
            })
            // Creo la cookie "EventoEnCreacion" con la id del evento como valor
            if (!Cookies.get('EventoEnCreacion_id')) {
                Cookies.set('EventoEnCreacion_id', response.data.insertedId, { expires: 7 });
                Cookies.set('EventoEnCreacion_estado', 2, { expires: 7 });
            }
            // envio al usuario al siguiente formulario
            setStatus(2)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <form className="CrearEvento" onSubmit={handleSubmit(onSubmit)}>

            <InfoBasica register={register} EventInfo={EventInfo} />

            <FechaHora register={register} EventInfo={EventInfo} />

            <Direcion register={register} EventInfo={EventInfo} />

            <Button
                className="siguiente"
                type='submit'
                variant="contained"
                color="secondary"
                size="large"
                sx={{ m: 1, width: '50%' }}
                endIcon={<SendIcon />}>
                Guardar
            </Button>
        </form>

    )
}
