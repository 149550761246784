// React
import { useState } from "react"
// cookies
import Cookies from 'js-cookie';
// css
import "./NavBar.css"


export default function CrearEventoNavBar({ Status, setStatus }) {
    // EstadoMaximo.    este use state almacena el valor maximo al que puede avanzar el usuario
    const [EstadoMaximo, SetEstadoMaximo] = useState(Cookies.get('EventoEnCreacion_estado'));

    return (
        <div className="container">
            <div className="CrearEventoNavBar">
                <div className="bar" />

                {Status == 1 ?
                    <div className="link active" onClick={() => setStatus(1)}>
                        <div className="circle activated actual">
                            <span> 1 </span>
                        </div>
                        <span>
                            Informacion basica
                        </span>
                    </div>
                    :
                    1 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(1)}>
                            <div className="circle activated">
                                <span> 1 </span>
                            </div>
                            <span>
                                Informacion basica
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 1 </span>
                            </div>
                            <span>
                                Informacion basica
                            </span>
                        </div>
                }

                {Status == 2 ?
                    <div className="link active" onClick={() => setStatus(2)}>
                        <div className="circle activated actual">
                            <span> 2 </span>
                        </div>
                        <span>
                            Tickets
                        </span>
                    </div>
                    :
                    2 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(2)}>
                            <div className="circle activated">
                                <span> 2 </span>
                            </div>
                            <span>
                                Tickets
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 2 </span>
                            </div>
                            <span>
                                Tickets
                            </span>
                        </div>
                }

                {Status == 3 ?
                    <div className="link active" onClick={() => setStatus(3)}>
                        <div className="circle activated actual">
                            <span> 3 </span>
                        </div>
                        <span>
                            Media
                        </span>
                    </div>
                    :
                    3 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(3)}>
                            <div className="circle activated">
                                <span> 3 </span>
                            </div>
                            <span>
                                Media
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 3 </span>
                            </div>
                            <span>
                                Media
                            </span>
                        </div>
                }

                {Status == 4 ?
                    <div className="link active" onClick={() => setStatus(4)}>
                        <div className="circle activated actual">
                            <span> 4 </span>
                        </div>
                        <span>
                            Informacion extra
                        </span>
                    </div>
                    :
                    4 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(4)}>
                            <div className="circle activated">
                                <span> 4 </span>
                            </div>
                            <span>
                                Informacion extra
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 4 </span>
                            </div>
                            <span>
                                Informacion extra
                            </span>
                        </div>
                }

                {Status == 5 ?
                    <div className="link active" onClick={() => setStatus(5)}>
                        <div className="circle activated actual">
                            <span> 5 </span>
                        </div>
                        <span>
                            Metodos de pago
                        </span>
                    </div>
                    :
                    5 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(5)}>
                            <div className="circle activated">
                                <span> 5 </span>
                            </div>
                            <span>
                                Metodos de pago
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 5 </span>
                            </div>
                            <span>
                                Metodos de pago
                            </span>
                        </div>
                }


                {Status == 6 ?
                    <div className="link active" onClick={() => setStatus(6)}>
                        <div className="circle activated actual">
                            <span> 6 </span>
                        </div>
                        <span>
                            Configuración
                        </span>
                    </div>
                    :
                    6 <= EstadoMaximo ?
                        <div className="link active" onClick={() => setStatus(6)}>
                            <div className="circle activated">
                                <span> 6 </span>
                            </div>
                            <span>
                                Configuración
                            </span>
                        </div>
                        :
                        <div className="link">
                            <div className="circle">
                                <span> 6 </span>
                            </div>
                            <span>
                                Configuración
                            </span>
                        </div>
                }



            </div>
        </div>
    )
}