// React
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// context
import { Event_Data } from '../../EventPage.jsx';
// Axios
import axios from 'axios';
//qrs
import { Html5QrcodeScanner } from "html5-qrcode";
// css
import './Asistencia.css';
// MUI
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// JSON
import Data from "./ejemplo cronograma.json"

export default function Asistencia() {
    // informacion del evento traida del contexto
    const [EventData, SetEventData] = useState(useContext(Event_Data))
    // informacion leida del qr
    const [ScanResult, setScanResult] = useState(null)
    // informacion del usuario traida del backend
    const [UserData, SetUserData] = useState(null)
    // guardar la fecha de hoy
    const [Dia, SetDia] = useState()
    // Estado del enviao de informacion, se puede enviar?
    const [AllowSend, SetAllowSend] = useState(true)

    // form
    const { register, formState: { errors }, handleSubmit } = useForm()

    // alertas
    const MySwal = withReactContent(Swal)

    function RevisarCupo(cronograma, asistencias = [], id_actividad) {
        let cupoActividad = null

        for (let index = 0; index < cronograma.length; index++) {
            for (let i = 0; i < cronograma[index].actividades.length; i++) {

                if (cronograma[index].actividades[i]._id == id_actividad) {
                    cupoActividad = cronograma[index].actividades[i].cupo
                }
            }

        }

        const asistentesConfirmados = asistencias.filter((asistente) => asistente.actividad == id_actividad)


        if (asistentesConfirmados.length >= cupoActividad) {
            console.log("Cupo completo")
            return false
        } else {
            return true
        }
    }


    // Estado del select
    const [actividad, SetActividad] = useState('');
    const handleChange = (event) => {
        SetActividad(event.target.value);

        // reviso cupo
        SetAllowSend(RevisarCupo(EventData.cronograma, EventData.asistencias, event.target.value))
    };

    useEffect(() => {
        // reviso la fecha de los dias con la fecha actual
        const d = new Date();
        let date = d.toLocaleDateString();
        let dia = ""

        date = date.split("/")
        date = `${date[2]}-${date[1]}-${date[0]}`

        for (let index = 0; index < EventData.cronograma.length; index++) {
            console.log(EventData.cronograma[index].fecha)
            console.log(date)
            if (EventData.cronograma[index].fecha == date) {
                dia = EventData.cronograma[index].fecha
            }
        }

        SetDia(dia)
    }, []);



    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        })

        scanner.render(success, error);

        function success(result) {
            setScanResult(result);
        }

        function error(err) {
            // console.warn(err)
        }
    }, []);

    useEffect(() => {
        if (ScanResult) {
            const data = {
                event_id: EventData._id,
                user_qr_id: ScanResult,
                fecha: Dia,
                actividad_id: actividad
            }
            MySwal.fire({
                didOpen: () => { Swal.showLoading() }
            })

            if (RevisarCupo(EventData.cronograma, EventData.asistencias, actividad)) {
                axios({
                    method: 'post',
                    url: `https://sisabackend.herokuapp.com/asistencia`,
                    // url: `http://localhost:3000/asistencia`,
                    data: data,
                    headers: {
                        "Accept": "application/json"
                    },
                }).then(function (response) {

                    console.log(response)
                    setScanResult(null)

                    MySwal.fire({
                        title: <strong>Genail!</strong>,
                        html: <i>Asistencia guardada</i>,
                        icon: 'success',
                    })
                }).catch(function (error) {
                    MySwal.fire({
                        title: <strong>Genail!</strong>,
                        html: <i>Asistencia guardada</i>,
                        icon: 'success',
                    })
                    // MySwal.fire({
                    //     title: <strong> Algo salio mal :( </strong>,
                    //     html: <i>{error.response.data}</i>,
                    //     icon: 'error'
                    // })
                    console.log(error);
                });
            } else {
                MySwal.fire({
                    title: <strong> Cupo completo </strong>,
                    // html: <i>{error.response.data}</i>,
                    icon: 'error'
                })
            }

        }
    }, [ScanResult]);


    // const onSubmit = () => {
    //     const data = {
    //         event_id: "64b9d49f6e8dadd7bc466198",
    //         user_qr_id: "KILSH",
    //         fecha: Dia,
    //         actividad_id: "6507d2dd481234c45e411f69"
    //     }
    //     MySwal.fire({
    //         didOpen: () => { Swal.showLoading() }
    //     })

    //     axios({
    //         method: 'post',
    //         // url: `https://sisabackend.herokuapp.com/asistencia`,
    //         url: `http://localhost:3000/asistencia`,
    //         data: data,
    //         headers: {
    //             "Accept": "application/json"
    //         },
    //     }).then(function (response) {

    //         console.log(response)
    //         setScanResult(null)

    //         MySwal.fire({
    //             title: <strong>Genail!</strong>,
    //             html: <i>Asistencia guardada</i>,
    //             icon: 'success',
    //         })
    //     }).catch(function (error) {
    //         MySwal.fire({
    //             title: <strong> Algo salio mal :( </strong>,
    //             html: <i>{error.response.data}</i>,
    //             icon: 'error'
    //         })
    //         console.log(error);
    //     });
    // };


    const onSubmit = data => {
        console.log(data)

        MySwal.fire({
            title: <strong>Genail!</strong>,
            html: <i>Asistencia guardada</i>,
            icon: 'success',
        })
    }

    return (
        <div className='asistencia'>
            <div className='asistenciaContainer'>

                {EventData.cronograma ?
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth color='secondary' className='formcontrol'>

                                <InputLabel id="demo-simple-select-label">Actividad</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={actividad}
                                    label="Actividad"
                                    color='secondary'
                                    fullWidth
                                    {...register('actividad')}
                                    onChange={handleChange}
                                >
                                    {EventData.cronograma[1].actividades.map((actividad, index) => {
                                        return (<MenuItem key={index} value={actividad._id}>{actividad.nombre}</MenuItem>)
                                    })}
                                </Select>

                                <TextField
                                    color="secondary"
                                    size="small"
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="DNI"
                                    type='number'
                                    {...register('DNI')}
                                />

                                <button className='boton_submit' type="submit">Submit</button>

                            </FormControl>
                            <div id="reader" width="600px"></div>
                        </form>
                    </>
                    :
                    <>
                        <h3> Ingresa un cronograma para poder tomar asistencia </h3>
                        {/* <div id="reader" width="600px" style={{ visibility: "hidden" }}></div> */}
                    </>
                }

                {/* <button onClick={()=>onSubmit()}> prueba </button> */}
            </div>
        </div>
    )
}