// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { OutlinedInput } from '@mui/material';
import Divider from '@mui/material/Divider';
// icons
import CloseIcon from '@mui/icons-material/Close';
// CSS
import "./Entradas.css"

export default function Entradas({ register, tickets_count, settickets_count }) {

    let tickets = []


    for (let index = 0; index <= tickets_count; index++) {

        if (index < 10) {
            tickets.push(
                <div key={index}>
                    <Form id={index} register={register} settickets_count={settickets_count} tickets_count={tickets_count}/>
                    <Divider />
                </div>
            )
        }
        if (index === 10) {
            tickets.push(
                <div key={index}>
                    <h1> Solo se pueden crear 5 entradas </h1>
                </div>
            )
        }

    }

    return (
        <div className="entradas">
            {tickets}
            <Button type="button" onClick={() => settickets_count(tickets_count + 1)}> Agregar </Button>
        </div>
    )
}



function Form({ id, register, settickets_count,  tickets_count}) {
    return (
        <div className='formulario_entradas'>
            <button  type="button" className='DeletButton'  onClick={() => settickets_count(tickets_count - 1)}>
                Eliminar Entrada
                <CloseIcon/>
            </button>
            <div className="nombre">
                <h3 className='nomargin'> Nombre </h3>
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    label="Nombre"
                    {...register(`tickets.${id}.nombre`)}
                />
            </div>
            <div className="nombre">
                <h3> Descripción </h3>
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    label="Descripción"
                    {...register(`tickets.${id}.Descripcion`)}
                />
            </div>

            <h3> Cantidad y Precio </h3>
            <div className="cantidad_precio">
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    label="Cantidad disponible"
                    type="number"
                    {...register(`tickets.${id}.cantidad_disponible`)}
                />
                <TextField
                    required
                    id="outlined-start-adornment"
                    color="secondary"
                    label="Cantidad maxima de compra"
                    type="number"
                    {...register(`tickets.${id}.cantidad_maxima_compra`)}
                />
                <TextField
                    required
                    id="outlined-start-adornment"
                    color="secondary"
                    label="precio"
                    type="number"
                    {...register(`tickets.${id}.precio`)}
                />
            </div>

            <h3> Fecha de inicio y fin de la venta de entradas </h3>
            <div className="fechas">
                <div className="inicio">
                    <OutlinedInput
                        type='date'
                        required
                        color="secondary"
                        {...register(`tickets.${id}.fecha_inicio`)}
                    />
                    <OutlinedInput
                        type='time'
                        color="secondary"
                        {...register(`tickets.${id}.hora_inicio`)}
                    />
                </div>
                <div className="fin">
                    <OutlinedInput
                        type='date'
                        required
                        color="secondary"
                        {...register(`tickets.${id}.fecha_final`)}
                    />
                    <OutlinedInput
                        type='time'
                        color="secondary"
                        {...register(`tickets.${id}.hora_final`)}
                    />
                </div>
            </div>
        </div>
    )
}