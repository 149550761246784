// React
import { useEffect, useState, useContext } from 'react';
// Config
import { BackendURL } from "../../../Config.js";
// Axios
import axios from 'axios';
// Components
import DataTable from './Inscriptos/Tabla';
import AddDeleteGerentes from './AgregarGerentes/AgregarGerentes';
import Asistencia from './Asistencia/Asistencia';
import EditarEvento from './EditarEvento/EditarEvento';
import Acreditar from './Acreditar/Acreditar';
// context
import { Event_Data } from '../EventPage.jsx';

// MUI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

export default function GerentesSection({ UserInfo }) {
    // informacion del evento traida del contexto
    const [EventData, SetEventData] = useState(useContext(Event_Data))
    // estado del boton "Editar evento"
    const [ShowEditar, SetShowEditar] = useState(false);
    // estado del boton "Inscriptos"
    const [ShowIscriptos, SetShowIscriptos] = useState(false);
    // estado del boton "Asistencia"
    const [ShowAsistencia, SetShowAsistencia] = useState(false);
    // estado del boton "Agregar / quitar gerentes"
    const [ShowAgregarGerentes, SetShowAgregarGerentes] = useState(false);

    // Cambio el estado de los botones cuando se hace click en uno de ellos
    const HandleChange = (boton) => {
        SetShowEditar(false);
        SetShowIscriptos(false);
        SetShowAsistencia(false);
        SetShowAgregarGerentes(false);

        switch (boton) {
            case 'Editar':
                SetShowEditar(!ShowEditar);
                break;
            case 'Inscriptos':
                SetShowIscriptos(!ShowIscriptos);
                break;
            case 'Asistencia':
                SetShowAsistencia(!ShowAsistencia);
                break;
            case 'Gerentes':
                SetShowAgregarGerentes(!ShowAgregarGerentes);
                break;
        }
    };

    // Columnas por defecto de la tabla
    let columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'nombre', headerName: 'Nombre', width: 250 },
        { field: 'DNI', headerName: 'DNI', width: 100 },
        { field: 'genero', headerName: 'Genero', width: 100 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'status', headerName: 'Estado del pago', width: 150 },
        { field: 'status_detail', headerName: 'Detalle del estado del pago', width: 250 },
        { field: 'tipo_entrada', headerName: 'Tipo de entrada', width: 500 },
        { field: 'total_pagado', headerName: 'Total a pagar', width: 150 },
    ];

    // informacion del evento traida del contexto
    // const [EventData, SetEventData] = useState(useContext(Event_Data))
    // filas de la tabla
    const [Rows, SetRows] = useState()
    const [Columns, SetColumns] = useState()
    // creo un array con los IDs de los iscriptos
    const UsersIDs = EventData.inscriptos.map(inscripto => {
        return inscripto.ID_usuario
    })

    const [Data, SetData] = useState()

    // pido la data de los inscriptos al backend
    useEffect(() => {
        axios({
            method: 'post',
            url: BackendURL("/users/multiinfo"),
            data: {
                UsersIDs: UsersIDs,
            },
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            SetData(response.data)

        }).catch(function (error) {

            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (Data && Data.length != 0) {
            // creo las columnas de la informacion extra
            // recorro los eventos asistidos de uno de los iscriptos al evento de interes
            for (let i = 0; i < Data[0].eventos_asistidos.length; i++) {
                // si el ID del evento asistido es igual al evento de interes:  
                if (Data[0].eventos_asistidos[i].ID_evento == EventData._id) {
                    // defino la variable "claves" como un array con las claves de la informacio enxtra de dicho evento
                    if (Data[0].eventos_asistidos[i].info_extra) {
                        let claves = Object.keys(Data[0].eventos_asistidos[i].info_extra)
                        // recorro el array "claves"
                        for (let index = 0; index < claves.length; index++) {
                            // agrego una columna a la variable "columns" por cada campo de informacion extra
                            columns.push({ field: claves[index], headerName: claves[index], width: 250 },)
                        }

                        break
                    }

                }
            }

            // seto el useState "Columns" como la variable "columns"
            SetColumns(columns)

            // formateo la data del inscripto y la gurado en la variable "rows"
            const rows = Data.map((inscripto, index) => {
                inscripto.id = index

                for (let index = 0; index < EventData.inscriptos.length; index++) {
                    if (EventData.inscriptos[index].ID_usuario == inscripto._id) {
                        inscripto.status = EventData.inscriptos[index].status
                        inscripto.status_detail = EventData.inscriptos[index].status_detail
                        // inscripto.tipo_entrada = EventData.inscriptos[index].tipo_entrada

                        const TiposEntrada = Object.keys(EventData.inscriptos[index].tipo_entrada)
                        const TiposEntradaNombreRecortado = TiposEntrada.map((entrada) => entrada.slice(0, 8) )

                        inscripto.tipo_entrada = TiposEntradaNombreRecortado.toString()
                        inscripto.total_pagado = EventData.inscriptos[index].total_pagado
                    }

                }

                for (let i = 0; i < inscripto.eventos_asistidos.length; i++) {
                    if (inscripto.eventos_asistidos[i].ID_evento == EventData._id) {
                        if (inscripto.eventos_asistidos[i].info_extra) {
                            let claves = Object.keys(inscripto.eventos_asistidos[i].info_extra)
                            for (let index = 0; index < claves.length; index++) {
                                let clave = claves[index]
                                inscripto[clave] = inscripto.eventos_asistidos[i].info_extra[clave]
                            }
                            break
                        }
                    }
                }
                return inscripto
            })

            // seto el useState "Rows" como la variable "rows"
            SetRows(rows)
        }

    }, [Data]);

    return (
        <div className='gerentes'>
            <div className='container'>
                {/* si el usuario es un gerente pero no el creador NO lo dejo editar el evento */}
                {EventData.creador == UserInfo.id ?
                    <span className='ButtonContainer'>
                        <Button
                            onClick={() => HandleChange("Editar")}
                            color="secondary"
                            variant="contained"
                            sx={{ m: 1, width: '20%' }}
                        >
                            Editar evento
                        </Button>
                    </span>
                    :
                    <Tooltip title="Sólo el creador del evento puede editarlo.">
                        <span className='ButtonContainer'>
                            <Button
                                disabled
                                color="secondary"
                                variant="contained"
                                sx={{ m: 1, width: '20%' }}
                            >
                                Editar evento
                            </Button>
                        </span>
                    </Tooltip>
                }

                <span className='ButtonContainer'>
                    <Button
                        onClick={() => HandleChange("Inscriptos")}
                        color="secondary"
                        variant="contained"
                        sx={{ m: 1, width: '20%' }}
                    >
                        Inscriptos
                    </Button>
                </span>
                {EventData.cronograma ?

                    <span className='ButtonContainer'>
                        <Button
                            onClick={() => HandleChange("Asistencia")}
                            color="secondary"
                            variant="contained"
                            sx={{ m: 1, width: '20%' }}
                        >
                            Asistencia
                        </Button>
                    </span>
                    :
                    <Tooltip title="Para tomar asistencia deves agregar un cronograma">
                        <span className='ButtonContainer'>
                            <Button
                                disabled
                                color="secondary"
                                variant="contained"
                                sx={{ m: 1, width: '20%' }}
                            >
                                Asistencia
                            </Button>
                        </span>
                    </Tooltip>
                }
                {/* {EventData.creador == UserInfo.id ?
                    <span className='ButtonContainer'>
                        <Button
                            onClick={() => HandleChange("Asistencia")}
                            color="secondary"
                            variant="contained"
                            sx={{ m: 1, width: '20%' }}
                        >
                            Asistencia
                        </Button>
                    </span>
                    :
                    <Tooltip title="Para poder tomar asistencia debes cargar el cronograma de tu evento.">
                    <span className='ButtonContainer'>
                        <Button
                            disabled
                            color="secondary"
                            variant="contained"
                            sx={{ m: 1, width: '20%' }}
                        >
                            Asistencia
                        </Button>
                    </span>
                </Tooltip>
                } */}

                {/* si el usuario es un gerente pero no el creador NO lo dejo agregar o quitar gerentes */}
                {/* {EventData.creador == UserInfo.id ? */}
                <span className='ButtonContainer'>
                    <Button
                        onClick={() => HandleChange("Gerentes")}
                        color="secondary"
                        variant="contained"
                        sx={{ m: 1, width: '20%' }}
                    >
                        Acreditar
                    </Button>
                </span>
                {/* :
                    <Tooltip title="Sólo el creador del evento puede editarlo.">
                        <span className='ButtonContainer'>
                            <Button
                                disabled
                                color="secondary"
                                variant="contained"
                                sx={{ m: 1, width: '20%' }}
                            >
                                Acreditar
                            </Button>
                        </span>
                    </Tooltip>
                } */}

            </div>

            {ShowIscriptos
                ?
                Rows
                    ?
                    <DataTable columns={Columns} rows={Rows} />
                    :
                    <h4>Aun no hay inscriptos a este evento</h4>
                :
                null
            }

            {/* {ShowAgregarGerentes ? <AddDeleteGerentes /> : null} */}
            {ShowAgregarGerentes ? <Acreditar usuariodata={Rows} /> : null}

            {ShowAsistencia ? <Asistencia /> : null}

            {ShowEditar ? <EditarEvento /> : null}


        </div>
    )
}