// React
import { useState, useEffect } from 'react';
// css
import "./Direcion.css"
// MUI
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';


export default function Direcion({ register, EventInfo }) {
    const [alignment, setAlignment] = useState('Por_definir');

    useEffect(() => {
        if (EventInfo) {
            setAlignment(EventInfo.event_tipe)
        }
    }, []);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        register('event_tipe', { value: newAlignment })
    };

    return (
        <div className="FormSection">
            <h2> Ubicación </h2>
            <p> Ayude a las personas en el área a descubrir su evento y dígales a los asistentes dónde presentarse. </p>

            <div className='togle_grup'>
                <ToggleButtonGroup
                    color="secondary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Ubicacion"
                >
                    <ToggleButton value="Presencial">Presencial</ToggleButton>
                    <ToggleButton value="Por_definir">Por definir</ToggleButton>
                    <ToggleButton value="Virtual">Virtual</ToggleButton>
                </ToggleButtonGroup>
            </div>

            {alignment == "Presencial" ? <Precencial register={register} EventInfo={EventInfo} /> : null}

            {alignment == "Virtual" ? <Virtual register={register} EventInfo={EventInfo} /> : null}

        </div>
    )
}


function Virtual({ register, EventInfo }) {
    return (
        <>
            <h3> Enlace del evento </h3>
            {EventInfo ?
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    defaultValue={EventInfo.event_url}
                    {...register("event_url")}
                />
                :
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    {...register("event_url")}
                />
            }

        </>
    )
}
function Precencial({ register, EventInfo }) {
    return (
        <>
            <h3> Direccion </h3>
            {EventInfo ?
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    defaultValue={EventInfo.event_location_name}
                    {...register("event_location_name")}
                />
                :
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    {...register("event_location_name")}
                />
            }

            <h3> Link de la Ubicacion </h3>
            {EventInfo ?
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    defaultValue={EventInfo.event_location_url}
                    {...register("event_location_url")}
                />
                :
                <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    {...register("event_location_url")}
                />
            }
        </>
    )
}