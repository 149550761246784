// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
// Config
import {BackendURL} from "../../Config";
// cookies
import Cookies from 'js-cookie';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// componentes
import Layout from "../Layout/Layout"

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


// custom css
import './login.css';

export default function Login() {
    const { forward_url } = useParams()

    // froms
    const [showPassword, setShowPassword] = useState(false);
    const [Error] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { register, formState: { errors }, handleSubmit } = useForm()

    // Alerts
    const MySwal = withReactContent(Swal)

    // navigation
    const navigate = useNavigate();

    const onSubmit = data => {

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/users/login'),
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Estas logueado!</i>,
                icon: 'success',
                buttonsStyling: false,
            })

            // creo la Cookie Token con el jwt con la data del usuario
            Cookies.set('Token', response.data.Token, { expires: 7 });
            // reviso si hay un evento en creacion
            if (response.data.EventoEnCreacion) {
                // si lo hay creo la Cookie EventoEnCreacion con el estado e id del evento en creacion
                Cookies.set('EventoEnCreacion_id', response.data.EventoEnCreacion.ID_evento, { expires: 7 });                
                Cookies.set('EventoEnCreacion_estado', response.data.EventoEnCreacion.status, { expires: 7 });                
            }

            // redirijo al usuario
            navigate('/' + forward_url.replace(/-/g, "/"))

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <Layout>
            <div className="login_page">
                <form className="Login_card" onSubmit={handleSubmit(onSubmit)}>
                    <img src='/imgs/logos/logo_morado.svg' alt="SISA logo" />
                    <h1> Inicia sesión </h1>

                    {Error ? <p style={{ color: "#FF0000" }}> E-Mail y/o Contraseña inválida </p> : errors.email?.type === 'pattern' ? <p style={{ color: "#FF0000" }}> E-Mail y/o Contraseña inválida </p> : <p> ¡Benvenido de vuelta! </p>}
                    {/* {errors.email?.type === 'pattern' ? <p style={{color: "#FF0000"}}> E-Mail y/o Contraseña inválida </p> : <p> ¡Benvenido de vuelta! </p> } */}




                    <FormControl sx={{ m: 1, width: '70%' }} variant="outlined">
                        <TextField
                            color="secondary"
                            size="small"
                            required
                            id="outlined-required"
                            label="E-Mail"
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }} variant="outlined">
                        <InputLabel
                            color="secondary"
                            size="small"
                            htmlFor="outlined-adornment-password">
                            Contraseña
                        </InputLabel>
                        <OutlinedInput
                            color="secondary"
                            size="small"
                            required
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            {...register('password', {
                                required: true
                            })}
                        />
                    </FormControl>

                    {/* <small>
                    <a href=""> Olvidé la contraseña </a>
                </small> */}
                    <Button
                        // onClick={() => setError(!Error)}
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        inicia sesion
                    </Button>

                    <div className='links_container'>
                        <small>
                            <a href={`/singin/${forward_url}`}> Crear una cuenta </a>
                        </small>

                        <small>
                            <a href={`/forgotPassword`}> Olvide mi contraseña </a>
                        </small>
                    </div>

                </form>
            </div>
        </Layout>
    )
}