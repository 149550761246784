// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// Config
import { BackendURL } from "../../../Config";
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// MUI
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
// css
import './MatchAle.css';


export default function MatchAle() {
    // Form
    const { register, formState: { errors }, handleSubmit } = useForm()
    // Alerts
    const MySwal = withReactContent(Swal)


    const onSubmit = data => {

        const newFormat = data.Data
            .replace(/(\r\n\t|\n|\r\t)/gm, '')
            .replace(/}{/g, '},{');

        const DataEnJson = [JSON.parse(newFormat)]

        console.log(DataEnJson)

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/MatchAle/createUsers'),
            data: DataEnJson,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Estas logueado!</i>,
                icon: 'success',
                buttonsStyling: false,
            })
            console.log(response)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }


    return (
        <div className='MatchAle'>
            <form className="Login_card" onSubmit={handleSubmit(onSubmit)}>
                <h1>Crear usuarios</h1>

                <TextField
                    color="secondary"
                    // size="small"
                    required
                    multiline
                    fullWidth
                    rows={6}
                    id="outlined-multiline-static-required"
                    label="Informacion en formato json"
                    {...register('Data')}
                />



                <Button
                    // onClick={() => setError(!Error)}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ m: 1, width: '50%' }}
                    endIcon={<SendIcon />}>
                    Guardar
                </Button>

            </form>
        </div>
    )
}