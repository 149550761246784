// React
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// analitics
import { Analytics } from '@vercel/analytics/react';

// Components
import Index from './components/index/index';
import Login from './components/Loging/login';
import Singin from './components/Singin/Singin';

// import CrearEvento from './components/CrearEvento/CrearEvento';
import NewCrearEventos from './components/new_CrearEvento/CrearEventos';

// import CrearEventoForm_1 from './components/new_CrearEvento/Form_1/Form_1'
import CrearEventoForm_2 from './components/new_CrearEvento/Form_2/Form_2'
import CrearEventoForm_3 from './components/new_CrearEvento/Form_3/Form_3';
import CrearEventoForm_4 from './components/new_CrearEvento/Form_4/Form_4'
import CrearEventoForm_5 from './components/new_CrearEvento/Form_5/Form_5'

import EventPage from './components/EventPage/EventPage';
import ComprarEntradas from './components/ComprarEntradas/ComprarEntradas';
import PerfilUsuario from './components/PerfilUsuario/PerfilUsuario';
import Eventos from './components/Eventos/Eventos'
import Entradas from './components/Entradas/Entrada';
import Admin from './components/Admin/Admin';
import CodigosPromocionales from './components/CodigosPromocionales/CodigosPromocionales';
import ForgotPass from './components/ForgotPass/ForgotPass';


import EliminarCuenta from './components/MatchAle/EliminarCuentas/EliminarCuenta';
import PoliticaPrivacidad from './components/MatchAle/PoliticaPrivacidad/PoliticaPrivacidad';

function App() {
  return (
    <>
      <Analytics/>
      <BrowserRouter>
      <Routes>
        {/* paguina de inicio */}
        <Route path="/" element={<Index/>} />
        <Route path="/home" element={<Index/>} />
        {/* panel de administracion */}
        <Route path='/admin' element={<Admin/>} />
        {/* acreditacion ingreso y registro */}
        <Route path="/login/:forward_url" element={<Login/>} />
        <Route path="/singin/:forward_url" element={<Singin/>} />
        {/* cambio de contrase */}
        <Route path="/forgotPassword" element={<ForgotPass/>} />
        <Route path="/forgotPassword/:ID_usuario" element={<ForgotPass/>} />
        {/* crear eventos */}
        <Route path="/crear" element={<NewCrearEventos/>} />
        {/* <Route path="/crear/infobasica" element={<CrearEventoForm_1/>} /> */}
        <Route path="/crear/tickets" element={<CrearEventoForm_2/>} />
        <Route path="/crear/media" element={<CrearEventoForm_3/>} />
        <Route path="/crear/infoextra" element={<CrearEventoForm_4/>} />
        <Route path="/crear/pagos" element={<CrearEventoForm_5/>} />
        {/* listado de todos los eventos */}
        <Route path="/eventos" element={<Eventos/>} />
        {/* paguina de evento espesificos */}
        <Route path="/evento/:id_evento" element={<EventPage/>} />
        <Route path="/evento/:id_evento/inscriptos" element={<EventPage/>} />
        {/* "carrito" de compra */}
        <Route path="/comprar/:id_evento" element={<ComprarEntradas/>} />
        {/* perfil del usuario */}
        <Route path="/perfil" element={<PerfilUsuario/>}/>

        {/* <Route path="/entrada" element={<Entradas/>}/> */}
        <Route path="/entrada/:id_evento/:id_usuario/:fecha_inicio" element={<Entradas/>}/>

        <Route path="/codigo/:id_evento/:codigo" element={<CodigosPromocionales/>}/>

        {/* Eliminar cuenta de MatchAle */}
        <Route path="/MatchAle/eliminar" element={<EliminarCuenta/>}/>
        <Route path="/MatchAle/politica" element={<PoliticaPrivacidad/>}/>

      </Routes>
    </BrowserRouter> 
    </>
    
  );
}

export default App;
