// React
import { useParams } from "react-router-dom";
// qr
import QRCode from "react-qr-code";

import "./Entrada.css"

export default function Entradas() {
    const { id_evento, id_usuario, fecha_inicio } = useParams();


    return (
        <div className='entradacontainer'>
            <div className='entrada'>
                <div className='section imgs'>

                    <div className='imgs falsofondo'>
                        <img src={`https://sisabackend.herokuapp.com/imgs/${id_evento}.png`} alt="" />
                    </div>
                    <img src={`https://sisabackend.herokuapp.com/imgs/${id_evento}.png`} className='fondo' alt="" />

                </div>
                <div className='section info Principal'>
                    <div className='qr'>
                        <QRCode
                            size={256}
                            style={{ height: "100%", Width: "100%", width: "100%" }}
                            // value={"https://www.withsisa.com/acreditar/649cf11e8bca586c548f0122/647cb4c49bad6d7e93d075f3"}
                            value={"647cb4c49bad6d7e93d075f3"}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>

                <div className='section info Secundaria'>
                    <div className='text'>
                        <h3> Fecha: </h3>
                        <span> {fecha_inicio} </span>
                        <a href={`https://www.withsisa.com/evento/${id_evento}`}> Mas informacion </a>
                    </div>
                </div>

                <div className='section info footer'>
                    <a href="https://www.withsisa.com">
                        <img src="https://www.withsisa.com/imgs/logos/logo_blanco.svg" alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}
