// React
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
// Config
import { BackendURL } from "../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// componentes
import Layout from "../Layout/Layout"
import CrearEventoNavBar from "./CrearEventoNavBar/NavBar";
import CrearEvento_1 from "./Form_1/Form_1";
import CrearEvento_2 from "./Form_2/Form_2";
import CrearEvento_3 from "./Form_3/Form_3";
import CrearEvento_4 from "./Form_4/Form_4";
import CrearEvento_5 from "./Form_5/Form_5";
// css
import "./CrearEventos.css"
// MUI
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CrearEventos() {
    // alerts
    const MySwal = withReactContent(Swal)
    // navigation
    const navigate = useNavigate();
    // EventInfo
    const [EventInfo, SetEventInfo] = useState();
    // backDrop
    const [open, setOpen] = useState(false);
    // form status
    const [Status, setStatus] = useState(1);

    useEffect(() => {
        const Token = Cookies.get('Token')
        const EventoEnCreacion_id = Cookies.get('EventoEnCreacion_id')

        if (!Token) {
            navigate('/login')
        }

        if (EventoEnCreacion_id) {
            setOpen(true);
            axios({
                method: 'post',
                url: BackendURL('/events/info'),
                data: {
                    event_id: EventoEnCreacion_id,
                },
                headers: {
                    "Accept": "application/json"
                },
            }).then(function (response) {
                SetEventInfo(response.data)

                console.log(response.data)

                setOpen(false);
                setStatus(response.data.EstadoCreacion)
            }).catch(function (error) {
                console.log(error);
            });

        } else {
            SetEventInfo(null)
        }
    }, []);


    return (
        <Layout>


            <CrearEventoNavBar Status={Status} setStatus={setStatus} />

            {Status == 1 ? <CrearEvento_1 EventInfo={EventInfo} setStatus={setStatus} /> : null}
            {Status == 2 ? <CrearEvento_2 EventInfo={EventInfo} setStatus={setStatus} /> : null}
            {Status == 3 ? <CrearEvento_3 EventInfo={EventInfo} setStatus={setStatus} /> : null}
            {Status == 4 ? <CrearEvento_4 EventInfo={EventInfo} setStatus={setStatus} /> : null}
            {Status == 5 ? <CrearEvento_5 EventInfo={EventInfo} setStatus={setStatus} /> : null}


            <Backdrop
                sx={{ color: '#9C27B0', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Layout>
    )
}
