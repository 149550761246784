// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
// Config
import {BackendURL} from "../../Config";
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// componentes
import Layout from "../Layout/Layout"

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


// custom css
import './ForgotPass.css';

export default function ForgotPass() {
    const { ID_usuario } = useParams()

    // froms
    const [showPassword, setShowPassword] = useState(false);
    const [Error] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { register, formState: { errors }, handleSubmit } = useForm()

    // Alerts
    const MySwal = withReactContent(Swal)

    // navigation
    const navigate = useNavigate();

    const onSubmit_email = data => {

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/users/forgotpassword'),
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>E-mail enviado</strong>,
                html: <i>Por favor revisa tu bandeja de entrada</i>,
                icon: 'success',
            })
            console.log(response)
        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    const onSubmit_pass = data => {
        console.log(data)

        if (data.password === data.password2) {

            delete data.password2

            data.id_usuario = ID_usuario

            // envio la data al backend
            MySwal.fire({
                didOpen: () => { Swal.showLoading() }
            })
            axios({
                method: 'post',
                url: BackendURL('/users/actualizar'),
                data: data,
                headers: {
                    "Accept": "application/json"
                },

            }).then(function (response) {
                MySwal.fire({
                    title: <strong>Genail!</strong>,
                    html: <i>Tus datos fueron actualizados!</i>,
                    icon: 'success'
                })
                console.log(response)

                navigate('/login/home')

            }).catch(function (error) {
                MySwal.fire({
                    title: <strong> Algo salio mal :( </strong>,
                    html: <i>{error.response.data}</i>,
                    icon: 'error'
                })
                console.log(error);
            });

        } else {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>Las contraseñas deven ser iguales</i>,
                icon: 'error'
            })
        }
    }

    return (
        <Layout>
            <div className="forgot_page">
                {ID_usuario ?
                    <form className="Login_card" onSubmit={handleSubmit(onSubmit_pass)}>
                        <h1> Elige una nueva contraseña  </h1>
                        <small> Asegúrese de que su nueva contraseña tenga 8 caracteres o más. Intente incluir números, letras y signos de puntuación para obtener una contraseña segura. </small>

                        <FormControl sx={{ m: 1, width: '70%' }}>
                            <InputLabel
                                size="small"
                                color="secondary"
                                htmlFor="outlined-adornment-password">Contraseña *</InputLabel>
                            <OutlinedInput
                                size="small"
                                color="secondary"
                                required
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                                {...register('password', {
                                    required: true
                                })}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: '70%' }}>
                            <InputLabel
                                size="small"
                                color="secondary"
                                htmlFor="outlined-adornment-password-2">Confirmar Contraseña *</InputLabel>
                            <OutlinedInput
                                size="small"
                                color="secondary"
                                required
                                id="outlined-adornment-password-2"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirmar contraseña"
                                {...register('password2', {
                                    required: true
                                })}
                            />
                        </FormControl>

                        <Button
                            // onClick={() => setError(!Error)}
                            type='submit'
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ m: 1, width: '70%' }}
                            endIcon={<SendIcon />}>
                            Guardar cambios
                        </Button>

                    </form>
                    :
                    <form className="Login_card" onSubmit={handleSubmit(onSubmit_email)}>
                        <h1> ¿Olvidaste tu contraseña? </h1>
                        <small> Por favor ingresa tu E-mail para que podamos enviarte un email con informacion para cambiar tu contraseña. </small>

                        <FormControl sx={{ m: 1, width: '70%' }} variant="outlined">
                            <TextField
                                color="secondary"
                                size="small"
                                required
                                id="outlined-required"
                                label="E-Mail"
                                {...register('email', {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                })}
                            />
                        </FormControl>

                        <Button
                            // onClick={() => setError(!Error)}
                            type='submit'
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ m: 1, width: '70%' }}
                            endIcon={<SendIcon />}>
                            Enviar e-mail
                        </Button>

                    </form>
                }
            </div>
        </Layout>
    )
}