// React
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
// Axios
import axios from 'axios';
// css
// import './PerfilUsuario.css';
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function InfoPersonal({Data, userID}) {

    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm()

    // navigation
    const navigate = useNavigate();

    const MySwal = withReactContent(Swal)

    const onSubmit = data => {

        data.ID = userID

        console.log(data)

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })

        axios({
            method: 'post',
            url: 'https://sisabackend.herokuapp.com/users/actualizar',
            // url: 'http://localhost:3000/users/actualizar',
            data: data,
            headers: {
                "Accept": "application/json"
                // 'Content-Type': "multipart/form-data"
            },
        }).then(function (response) {
            setTimeout(() => {
                window.location.reload(true)
            }, "1500");

            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tus datos fueron actualizados!</i>,
                timer: 1500,
                timerProgressBar: true,
                icon: 'success'
            })

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <div className='actualizarContainer'>
            <form className="actualizar" onSubmit={handleSubmit(onSubmit)}>
            {/* <form className="actualizar"> */}
                <h2> Actualizar datos personales </h2>

                <div className='fila'>
                    <span> Nombre </span>
                    <TextField
                        color="secondary"
                        size="small"
                        fullWidth
                        id="outlined"
                        label={Data.nombre}
                        {...register('nombre')}
                    />
                </div>
                <div className='fila'>
                    <span> DNI </span>
                    <TextField
                        color="secondary"
                        size="small"
                        fullWidth
                        id="outlined"
                        label={Data.DNI}
                        {...register('DNI')}
                    />
                </div>
                <div className='fila'>
                    <span> Email </span>
                    <TextField
                        color="secondary"
                        size="small"
                        fullWidth
                        id="outlined"
                        label={Data.email}
                        {...register('email')}
                    />
                </div>
                
                {/* <div className='fila'>
                    <span> Certificado academico </span>
                    <input type="file" name="certificado_academico" id="certificado_academico" {...register("certificado_academico")} />
                </div> */}

                <Button
                    // onClick={() => onSubmit()}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ m: 1, width: '50%' }}
                    endIcon={<SendIcon />}>
                    Actualizar
                </Button>
            </form>
        </div>
    )
}