// React
import { useNavigate } from "react-router-dom";

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

// componentes
import Layout from "../Layout/Layout"
import CardsComponent from "./CardsComponent/CardsComponent"
import PropValor from "./PropuestaValor/PropValor";

// img
import SISAcard from "./imgs/sisa card.png"

// custom css
import './css/index.css';
import './css/header.css';
import './css/featuredEvents.css';


export default function Index() {
  const navigate_to_ = useNavigate();

  return (
    <Layout>
      <div className="index">
        <section id='header'>
          <div className='top'>
            <div className='izquierda'>
              <img src="/imgs/logos/logo_blanco.svg" alt="sisa logo" />
              <p>
                Bienvenido a nuestro sistema de inscripción a eventos todo incluido. Regístrese para nuestro próximo evento con facilidad y disfrute de un proceso de registro simplificado.
              </p>

              <Button
                onClick={() => navigate_to_("/singin/home")}
                type='link'
                variant="contained"
                color="secondary"
                size="large"
                sx={{ m: 1, width: '50%' }}
                endIcon={<SendIcon />}>
                Regístrate
              </Button>
            </div>
            <div className='derecha'>
              <img src={SISAcard} alt="sisa logo" />
            </div>
          </div>
          <div className='bottom'>
            <h1> BENEFICIOS DE SISA </h1>
            <CardsComponent />

          </div>
        </section>

        {/* <section id="header_transition"></section> */}

        {/* <section id="featured_events">
        <h1> EVENTOS DESTACADOS </h1>
        </section> */}

        <PropValor/>

      </div>
    </Layout>
  )
}