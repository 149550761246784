// React
import * as React from 'react';
import { useNavigate } from "react-router-dom";
// cookies
import Cookies from 'js-cookie';
// JWT
import { useJwt } from "react-jwt";
// MUI
import Avatar from '@mui/material/Avatar';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// custom css
import './Navbar.css';
// imgs
import sisaFavIcon from "./imgs/sisa-website-favicon-white 1.png"

export default function Navbar() {
  // navigation
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const romoveCookie = () => {
    Cookies.remove('Token')
    navigate('/login/home')
  };

  const Token = Cookies.get('Token');

  const { decodedToken, isExpired } = useJwt(Cookies.get('Token'));

  return (
    <div className="Navbar">
      <a href="/">
        <img src={sisaFavIcon} alt="sisa favicon" />
      </a>
      <div className='menu_container'>

        {/* Si existe el token muestro el boton prara crear eventos y el perfil*/}
        {/* {Token ? <a href="/crear"> <AddOutlinedIcon /> Crear Evento </a> : null} */}

        <a href="/eventos" className='menu_link'> Eventos </a>
        
        {Token ? <a href="/perfil" className='menu_link'> Perfil </a> : null}


        {Token ? 
          decodedToken ? 
            decodedToken.admin ? 
              <a href="/admin" className='menu_link'> Admin </a> 
            : null
          : null 
        : null}

        {Token ? null : <a href="/login/home" className='menu_link'> Ingresar </a>}
        {Token ? null : <a href="/singin/home" className='menu_link'> Registrarse </a>}


        {/* {Token ? <button id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}> <Avatar className='avatar' src="/broken-image.jpg" /> </button> : null} */}

        {/* Si NO existe el token muesto los links a singin y login */}
        {/* {Token ? null : <a href="/login/home" className='menu_link'> Login </a>}
        {Token ? null : <a href="/singin/home" className='menu_link'> SingIn </a>}
        


        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          {Token ? <MenuItem onClick={handleClose}> <a href="/perfil" className='menu_link'> Perfil </a> </MenuItem> : <MenuItem onClick={handleClose}> <a href="/login/home" className='menu_link'> LogIn </a> </MenuItem>}
          
          {Token ? <MenuItem onClick={() => {setAnchorEl(null); romoveCookie() ;}}>Logout</MenuItem> : <MenuItem onClick={handleClose}> <a href="/singin/home" className='menu_link'> SingIn </a> </MenuItem>}

        </Menu> */}
      </div>

    </div>
  )
}