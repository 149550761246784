// React
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// Axios
import axios from 'axios';
// css
import './Cronograma.css';
// context
import { Event_Data } from '../../../EventPage.jsx';
// Components
import Dias from './CronogramaBasio/CronogramaBasio';
// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function Cronograma() {
    // informacion del evento traida del contexto
    const [EventData, SetEventData] = useState(useContext(Event_Data))

    const MySwal = withReactContent(Swal)

    const { register, formState: { errors }, handleSubmit, watch } = useForm()

    // console.log(EventData)

    const onSubmit = data => {

        data.id_evento = EventData._id

        console.log(data)

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: 'https://sisabackend.herokuapp.com/asistencia/cronograma',
            // url: 'http://localhost:3000/asistencia/cronograma',
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Estas logueado!</i>,
                icon: 'success',
                buttonsStyling: false,
            })
            // navigate('/' + forward_url.replace("-", "/"))

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    const [diasCount, SetDiasCount] = useState(0);


    return (
        <div id='Cronograma'>
            <h1> Cronograma </h1>
            <form action="" onSubmit={handleSubmit(onSubmit)}>

                <div className='diaContainer'>

                    {EventData.cronograma ? <DiasConData cronograma={EventData.cronograma} register={register} /> : <Dias diasCount={diasCount} register={register} />}


                    <div className='agregar_dia'>
                        <Button
                            onClick={() => SetDiasCount(diasCount + 1)}
                            type='button'
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ m: 1, width: '50%' }}>
                            Agregar dia
                        </Button>
                    </div>
                </div>


                <Button
                    // onClick={() => setError(!Error)}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    sx={{ m: 1, width: '50%' }}
                    endIcon={<SendIcon />}>
                    Guardar
                </Button>

            </form>
        </div>
    );

}












function DiasConData({ cronograma, register }) {
    const [actividadesCout, SetActividadesCout] = useState(0);

    return cronograma.map((dia, index_dia) => {
        return (
            <div className='dia'>
                <div className='title'>
                    <h3> Dia {index_dia} </h3>
                    <TextField
                        color="secondary"
                        size="small"
                        required
                        id="outlined-required"
                        label="Nombre del Dia"
                        defaultValue={dia.nombre}
                        {...register(`cronograma[${index_dia}].nombre`)}
                    />
                    <TextField
                        color="secondary"
                        size="small"
                        required
                        id="outlined-required"
                        label="Fecha del dia"
                        defaultValue={dia.fecha}
                        {...register(`cronograma[${index_dia}].fecha`)}
                    />
                </div>

                {dia.actividades.map((actividad, index_actividad) => {
                    return (
                        <Actividad actividad={actividad} register={register} index_actividad={index_actividad} index_dia={index_dia} />
                    )
                })}

                {/* <Actividades actividadesCout={actividadesCout} register={register} dia_index={index} /> */}

                <Button
                    onClick={() => SetActividadesCout(actividadesCout + 1)}
                    type='button'
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    sx={{ m: 1, width: '50%' }}>
                    Agregar actividad
                </Button>
            </div>
        )
    })
}


function Actividad({ actividad, register, index_actividad, index_dia }) {
    // register(`cronograma[${index_dia}].actividades[${index_actividad}].id`, { value: `${index_dia}_${index_actividad}` })
    register(`cronograma[${index_dia}].actividades[${index_actividad}].asistentes`, { value: [] })
    return (
        <div className='actividad'>
            <h4> Actividad {index_actividad} </h4>
            <TextField
                color="secondary"
                size="small"
                required
                id="outlined-required"
                label="Nombre de la actividad"
                defaultValue={actividad.nombre}
                {...register(`cronograma[${index_dia}].actividades[${index_actividad}].nombre`)}
            />
            <TextField
                color="secondary"
                size="small"
                required
                id="outlined-required"
                label="Hora incio"
                defaultValue={actividad.hora_inicio}
                {...register(`cronograma[${index_dia}].actividades[${index_actividad}].hora_inicio`)}
            />
            <TextField
                color="secondary"
                size="small"
                required
                id="outlined-required"
                label="Hora final"
                defaultValue={actividad.hora_final}
                {...register(`cronograma[${index_dia}].actividades[${index_actividad}].hora_final`)}
            />
            <TextField
                color="secondary"
                size="small"
                required
                id="outlined-required"
                label="cupo maximo"
                defaultValue={actividad.cupo}
                {...register(`cronograma[${index_dia}].actividades[${index_actividad}].cupo`)}
            />
            <div className='CheckBoxContainer'>
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            color="secondary"
                            defaultValue={actividad.mostrar}
                            {...register(`cronograma[${index_dia}].actividades[${index_actividad}].mostrar`)}
                        />
                    }
                    label="Mostrar a asistentes"
                    color="secondary"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="secondary"
                            defaultValue={actividad.asistencia}
                            {...register(`cronograma[${index_dia}].actividades[${index_actividad}].asistencia`)}
                        />
                    }
                    label="Incluida en la asistencia"
                />
            </div>
        </div>
    )
}

