// React
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useJwt } from "react-jwt";
// Config
import {BackendURL} from "../../Config";
// Axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie';
// componentes
import Layout from '../Layout/Layout'
import DataTable from './Usuarios/Tabla';
import Mailing from './Meiling/Mailing';
import Configuracion from './Configuracion/Configuracion';
import MatchAle from './MatchAle/MatchAle';
// css
import './Admin.css';
// MUI
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

export default function Admin() {

    // navigation
    const navigate = useNavigate();

    const { decodedToken, isExpired } = useJwt(Cookies.get('Token'));

    console.log(decodedToken)

    const navigate_to_ = useNavigate();

    // reviso si el usuario esta logueado
    useEffect(() => {
        if (!Cookies.get('Token')) {
            navigate_to_('/home')
        }
        if (decodedToken) {
            if (!decodedToken.admin) {
                navigate_to_('/home')
            }
        }
    }, [decodedToken]);


    // estado del boton "Usuarios"
    const [ShowUsuarios, SetShowUsuarios] = useState(false);
    // estado del boton "Eventos"
    const [ShowEventos, SetShowEventos] = useState(false);
     // estado del boton "Meiling"
    const [ShowMailing, SetShowMailing] = useState(false);
     // estado del boton "Configuracion"
    const [ShowConfiguracion, SetConfiguracion] = useState(false);
     // estado del boton "MatchAle"
    const [ShowMatchAle, SetShowMatchAle] = useState(false);

    // Cambio el estado de los botones cuando se hace click en uno de ellos
    const HandleChange = (boton) => {
        SetShowUsuarios(false);
        SetShowEventos(false);
        SetShowMailing(false);
        SetConfiguracion(false);
        SetShowMatchAle(false);

        switch (boton) {
            case 'Usuarios':
                SetShowUsuarios(!ShowUsuarios);
                break;
            case 'Eventos':
                SetShowEventos(!ShowEventos);
                break;
            case 'Mailing':
                SetShowMailing(!ShowMailing);
                break;
            case 'Configuracion':
                SetConfiguracion(!ShowConfiguracion);
                break;
            case 'MatchAle':
                SetShowMatchAle(!ShowMatchAle);
                break;
        }
    };

    const [Data, SetData] = useState()
    const [Rows, SetRows] = useState()
    // const [Columns, SetColumns] = useState()

    // Columnas por defecto de la tabla
    let Columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: '_id', headerName: 'DB ID', width: 250 },
        { field: 'nombre', headerName: 'Nombre', width: 250 },
        { field: 'DNI', headerName: 'DNI', width: 100 },
        { field: 'genero', headerName: 'Genero', width: 100 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'fecha_creacion', headerName: 'Fecha de creacion', width: 150 },
        { field: 'cantidad_eventos_asistidos', headerName: 'Eventos asistidos', width: 250 },
    ];

    // SetColumns(columns)

    // pido la data de los inscriptos al backend
    useEffect(() => {
        axios({
            method: 'get',
            url: BackendURL('/users/allinfo'),
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            SetData(response.data)

            console.log(response.data);

            // SetRows(response.data)

        }).catch(function (error) {

            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (Data) {
            // creo las columnas de la informacion extra
            // recorro los eventos asistidos de uno de los iscriptos al evento de interes
            // for (let i = 0; i < Data[0].eventos_asistidos.length; i++) {
            //     // si el ID del evento asistido es igual al evento de interes:  
            //     if (Data[0].eventos_asistidos[i].ID_evento == EventData._id) {
            //         // defino la variable "claves" como un array con las claves de la informacio enxtra de dicho evento
            //         let claves = Object.keys(Data[0].eventos_asistidos[i].info_extra)
            //         // recorro el array "claves"
            //         for (let index = 0; index < claves.length; index++) {
            //             // agrego una columna a la variable "columns" por cada campo de informacion extra
            //             columns.push({ field: claves[index], headerName: claves[index], width: 250 },)
            //         }

            //         break
            //     }
            // }

            // // seto el useState "Columns" como la variable "columns"
            // SetColumns(columns)

            // formateo la data del inscripto y la gurado en la variable "rows"
            const rows = Data.map((inscripto, index) => {
                inscripto.id = index

                if (inscripto.eventos_asistidos) {
                    inscripto.cantidad_eventos_asistidos = inscripto.eventos_asistidos.length
                } else {
                    inscripto.cantidad_eventos_asistidos = 0
                }

               
                

                // for (let index = 0; index < EventData.inscriptos.length; index++) {
                //     if (EventData.inscriptos[index].ID_usuario == inscripto._id) {
                //         inscripto.status = EventData.inscriptos[index].status
                //         inscripto.status_detail = EventData.inscriptos[index].status_detail
                //         inscripto.tipo_entrada = EventData.inscriptos[index].tipo_entrada
                //         inscripto.total_pagado = EventData.inscriptos[index].total_pagado
                //     }

                // }

                // for (let i = 0; i < inscripto.eventos_asistidos.length; i++) {
                //     if (inscripto.eventos_asistidos[i].ID_evento == EventData._id) {
                //         let claves = Object.keys(inscripto.eventos_asistidos[i].info_extra)
                //         for (let index = 0; index < claves.length; index++) {
                //             let clave = claves[index]
                //             inscripto[clave] = inscripto.eventos_asistidos[i].info_extra[clave]
                //         }
                //         break
                //     }
                // }
                return inscripto
            })

            // seto el useState "Rows" como la variable "rows"
            SetRows(rows)
        }

    }, [Data]);


    return (
        <Layout>

            <div id='Admin'>
                <div className='header'>
                    <div className='container'>
                        <Button color="secondary" onClick={() => HandleChange("Usuarios")}> Usuarios </Button>
                        <Button color="secondary" onClick={() => HandleChange("Eventos")}> Eventos </Button>
                        <Button color="secondary" onClick={() => HandleChange("Mailing")}> Mailing </Button>
                        <Button color="secondary" onClick={() => HandleChange("Configuracion")}> Configuracion </Button>
                        <Button color="secondary" onClick={() => HandleChange("MatchAle")}> MatchAle </Button>
                    </div>
                </div>
                <div className='body'>
                    {ShowUsuarios ?  <DataTable columns={Columns} rows={Rows} /> : null}

                    {ShowMailing ?  <Mailing/> : null}

                    {ShowConfiguracion ?  <Configuracion/> : null}

                    {ShowMatchAle ?  <MatchAle/> : null}
                   
                </div>
            </div>

        </Layout>
    )
}