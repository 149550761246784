// MUI
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
// css
import './cards.css';

export default function CardsComponent() {
    return (
        <div className='cards_div'>

            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <GroupsOutlinedIcon />
                </div>
                <div className='text_container'>
                    <h2> Registro de asistentes </h2>
                    <p>
                        SISA permite a los organizadores de eventos registrar a los asistentes de manera fácil y rápida. Los asistentes pueden registrarse en línea y los organizadores pueden mantener un seguimiento en tiempo real de los registros.
                    </p>
                </div>
            </div>

            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <CalendarMonthOutlinedIcon/>
                </div>
                <div className='text_container'>
                    <h2> Gestión de eventos </h2>
                    <p>
                        SISA facilita la gestión de eventos a los organizadores, permitiéndoles llevar un seguimiento de los detalles importantes del evento, como la fecha, el lugar y la hora, así como de los materiales y recursos necesarios para el evento.
                    </p>
                </div>
            </div>


            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <FormatListBulletedOutlinedIcon/>
                </div>
                <div className='text_container'>
                    <h2> Seguimiento de asistentes </h2>
                    <p>
                        SISA permite a los organizadores hacer un seguimiento de los asistentes antes, durante y después del evento. Esto incluye el seguimiento de la asistencia, la recopilación de comentarios y la obtención de información valiosa sobre los asistentes.
                    </p>
                </div>
            </div>

            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <AutoFixHighOutlinedIcon />
                </div>
                <div className='text_container'>
                    <h2> Personalización </h2>
                    <p>
                        SISA permite a los organizadores personalizar la plataforma según las necesidades específicas de su evento. Pueden personalizar la información que se recopila de los asistentes, las opciones de registro y la configuración de la plataforma.
                    </p>
                </div>
            </div>
            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <WatchLaterOutlinedIcon />
                </div>
                <div className='text_container'>
                    <h2> Ahorro de tiempo </h2>
                    <p>
                        SISA permite a los organizadores ahorrar tiempo y esfuerzo en la gestión de eventos. La plataforma automatiza muchos procesos de gestión de eventos, lo que reduce la carga de trabajo y permite a los organizadores centrarse en otros aspectos importantes del evento.
                    </p>
                </div>
            </div>

            <div className='tarjeta_beneficios'>
                <div className='icon_conteiner'>
                    <TimelineOutlinedIcon />
                </div>
                <div className='text_container'>
                    <h2> Análisis de resultados </h2>
                    <p>
                        SISA ofrece a los organizadores la posibilidad de analizar los resultados del evento y hacer un seguimiento de los KPIs. Esto permite a los organizadores hacer un seguimiento de los resultados de su evento y tomar decisiones informadas sobre futuros eventos.
                    </p>
                </div>
            </div>

        </div>
    )
}