// React
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
// Config
import { BackendURL } from "../../Config";
// Axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie';
// componentes
import Layout from '../Layout/Layout';
import Entrada from './Entradas/Entradas';
import DescuentosAPIs from './DescuentosAPIs/DescuentosAPIs';
// css
import './ComprarEntradas.css';
// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
// icons
import CloseIcon from '@mui/icons-material/Close';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import data from './ejemplo data.json'

export default function ComprarEntradas() {
    // reviso si el usuario esta logueado, si no esta logueado lo envio al login
    const navigate = useNavigate();
    useEffect(() => {
        if (!Cookies.get('Token')) {
            navigate('/login')
        }
    }, []);
    // guardo en "Token" el token que viene de cookies
    const Token = Cookies.get('Token')
    // creo el state "Data" para la informacion del evento que viene del backend
    const [Data, SetData] = useState();
    // creo el state "TotalGlobal" para guardar el total a pagar
    const [TotalGlobal, SetTotalGlobal] = useState('0000');
    // creo el state "TotalGlobalDescuentoDesHabilitado" para guardar el total de las entradas a las que no se le aplican descuentos
    const [TotalGlobalDescuentoDesHabilitado, SetTotalGlobalDescuentoDesHabilitado] = useState('0000');
    // creo el state "Descuento" para guardar el total a restar en descuentos
    const [Descuento, SetDescuento] = useState('0000');
    // creo el state "Actualizar" que voy a usar para saver cuando tengo que actualizar el total que se muestra
    const [Actualizar, SetActualizar] = useState();
    // creo la constante "id_evento" con el id del evento que viene de parametros de la url
    const { id_evento } = useParams()
    // creo el state "open" para guardar el estado del backdrop de informacion extra
    const [open, setOpen] = useState(false);
    // uso el "useForm()" hook
    const { register, formState: { errors }, handleSubmit, watch, setValue } = useForm()
    // Creo la constante "MySwal" con la funcion "withReactContent" y "Swal"
    const MySwal = withReactContent(Swal)

    // console.log("kk")

    // Pido la nformacion del evento al backend
    useEffect(() => {
        axios({
            method: 'post',
            url: BackendURL('/events/info'),
            data: {
                event_id: id_evento,
            },
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            console.log(response)
            SetData(response.data)
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    // Cantidad de entradas + cupon de descuent0
    useEffect(() => {
        // console.log(watch().tickets)
        // si existe la key "tickets" dentro del formulario:
        if (watch().tickets) {
            let tickets = Object.entries(watch().tickets)           // guardo las keys de "watch().tickets" dentro de la variable tickets
            let total = 0
            let TotalDescuentoHabilitado = 0

            // recorro la varialbe "tickets" donde cada posision de la lista es una key de "watch().tickets"
            for (let index = 0; index < tickets.length; index++) {
                // si no existe la key "cantidad", la creo y le doy valor 0
                if (!tickets[index][1].cantidad) {
                    tickets[index][1].cantidad = 0
                } else {
                    // si si existe la key "cantidad": 
                    // creo la key "total" y le doy el valor de la cantidad de tickets multiplicado por el precio
                    tickets[index][1].total = tickets[index][1].cantidad * parseInt(tickets[index][1].precio)

                    // guardo en la variable total el valor anterior de la variable mas el total actual
                    total = total + (tickets[index][1].cantidad * parseInt(tickets[index][1].precio))

                    // si a la entrada NO se le aplican descuentos, sumo el valor de la entrada a "TotalDescuentoHabilitado"
                    if (!tickets[index][1].descuento) {
                        TotalDescuentoHabilitado = TotalDescuentoHabilitado + (tickets[index][1].cantidad * parseInt(tickets[index][1].precio))
                    }
                }
            }

            SetTotalGlobal(total)
            SetTotalGlobalDescuentoDesHabilitado(TotalDescuentoHabilitado)

            if (
                watch().tickets['MÓDULO 1 Medicina Narrativa: Implicancias en la relación médico paciente']?.cantidad > 0 &&
                watch().tickets['MÓDULO 2: LEGISLACIÓN - NORMATIVAS DERECHO DE LA LA SALUD']?.cantidad > 0 &&
                watch().tickets['MÓDULO 3: PRÁCTICAS DE CULTIVO Y TRAZABILIDAD']?.cantidad > 0 &&
                watch().tickets['MÓDULO 4: ANALÍTICA DEL CANNABISNANOTECNOLOGÍA- PRODUCTOS DE CALIDAD']?.cantidad > 0 &&
                watch().tickets['MÓDULO 5: INVESTIGACIÓN: ¿POR QUÉ? ¿PARA QUÉ? Y ¿CÓMO? APORTES A LA EVIDENCIA']?.cantidad > 0 &&
                watch().tickets['MÓDULO 6: TOXICOLOGÍA DEL CANNABIS EN EL SIGLO XXI']?.cantidad > 0 &&
                watch().tickets['MÓDULO 7: USOS CLÍNICOS EVIDENCIA CIENTÍFICA GUÍAS CLÍNICAS']?.cantidad > 0
            ) {
                SetDescuento(total + 30400)
            } else {
                SetDescuento('0000')
            }
        }

        // si existe la key "descuentos" dentro del formulario:
        if (watch().descuentos) {
            // guardo las keys de "watch().descuentos" dentro de la variable tickets
            let descuentos = Object.entries(watch().descuentos)
            // guardo los porcentajes de descuento en la constante "lista_descuentos"
            const lista_descuentos = descuentos.map(descuento => descuento[1])
            // ordeno "lista_descuentos" de mayor a menor
            lista_descuentos.sort(function (a, b) { return b - a });
            // Declaro la variable "total" como el total con descuentos (TotalGlobal) menos el total sin descuentos (TotalGlobalDescuentoDesHabilitado)
            let total = TotalGlobal - TotalGlobalDescuentoDesHabilitado
            // recorro "lista_descuentos":
            for (let index = 0; index < lista_descuentos.length; index++) {
                // Declaro la constante "descuento" y le doy el valor del descuento haciendo regla de 3 simple
                const descuento = (total / 100) * lista_descuentos[index]
                // Le resto el descuento al total
                total = total - descuento
            }


            let totalDescuento = TotalGlobal - total

            // ----------------------------  ajuste de entrada en caso espesifico -------------------------------------
            // if (watch().tickets['Entrada BASICA'].cantidad > 0) {
            //     if (watch().descuentos.amprosafiliado && !watch().descuentos.asociacionafiliado) {
            //         totalDescuento = totalDescuento - 1400
            //     }
            //     if (watch().descuentos.amprosafiliado && watch().descuentos.asociacionafiliado) {
            //         totalDescuento = totalDescuento + 710
            //     }
            //     if (watch().descuentos.enfermeros) {
            //         totalDescuento = totalDescuento + 800
            //     }
            //     if (watch().descuentos.amprosafiliado && watch().descuentos.enfermeros) {
            //         totalDescuento = totalDescuento - 1840
            //     }
            //     if (watch().descuentos.amprosafiliado && watch().descuentos.enfermeros && watch().descuentos.asociacionafiliado) {
            //         totalDescuento = totalDescuento - 3244
            //     }
            // }
            // if (watch().tickets['Entrada general'].cantidad > 0) {
            //     if (watch().descuentos.Alumno) {
            //         totalDescuento = 0
            //     }
            //     if (watch().descuentos.enfermeros) {
            //         totalDescuento = totalDescuento + 200
            //     }
            //     if (watch().descuentos.amprosafiliado && watch().descuentos.enfermeros) {
            //         totalDescuento = totalDescuento - 5760
            //     }
            //     if (watch().descuentos.amprosafiliado && watch().descuentos.enfermeros && watch().descuentos.asociacionafiliado) {
            //         totalDescuento = totalDescuento - 7776
            //     }
            // }
            // ----------------------------  ajuste de entrada en caso espesifico -------------------------------------

            // SetDescuento(TotalGlobal - total)
            SetDescuento(totalDescuento)
        }

    }, [watch(), Actualizar]);


    // onSubmit GetNet
    const onSubmit = data => {
        // lanzo la alerta de carga
        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })

        const total = Descuento != 0 ? Math.round((TotalGlobal - Descuento) + TotalGlobalDescuentoDesHabilitado) : TotalGlobal
        var link = ''

        // reviso si se selecciono alguna entrada
        // Declaro la variable "tickets" como una lista con las keys de "data.tickets"
        let tickets = Object.entries(data.tickets)
        let HayTickets = false
        let totalAPagar = 0o0

        console.log(total)


        if (total == 24000) {
            link = "https://checkout.globalgetnet.com.ar/orders/90920193-c3f6-417f-b607-022c2f2d0a7b"
        }
        if (total == 32000) {
            link = "https://checkout.globalgetnet.com.ar/orders/820df44f-b9f1-4a96-af1e-1366568cff43"
        }
        if (total == 48000) {
            link = "https://checkout.globalgetnet.com.ar/orders/caa42fe1-3174-417f-a7bf-420c11be2028"
        }
        if (total == 56000) {
            link = "https://checkout.globalgetnet.com.ar/orders/56cfa5d6-a577-4aa5-98dd-6d989efe841e"
        }
        if (total == 88000) {
            link = "https://checkout.globalgetnet.com.ar/orders/cc78463f-3910-4894-951b-ed577f162321"
        }
        if (total == 80000) {
            link = "https://checkout.globalgetnet.com.ar/orders/8dbc028c-3e21-48b2-9d57-355930193fda"
        }
        if (total == 72000) {
            link = "https://checkout.globalgetnet.com.ar/orders/13413575-8211-4155-8290-5fa5d9d1e591"
        }
        if (total == 112000) {
            link = "https://checkout.globalgetnet.com.ar/orders/4308db38-5a0c-4ff8-9450-d6e2eb792fdc"
        }
        if (total == 104000) {
            link = "https://checkout.globalgetnet.com.ar/orders/0c6cd0b1-29af-46a6-aa51-398f2719f2d3"
        }
        if (total == 128000) {
            link = "https://checkout.globalgetnet.com.ar/orders/a1ff9de3-bed3-4c06-802f-867d081e5244"
        }
        if (total == 168000) {
            link = "https://checkout.globalgetnet.com.ar/orders/93f905c3-3da1-43ba-a12e-8db07c32e9c4"
        }
        if (total == 96000) {
            link = "https://checkout.globalgetnet.com.ar/orders/b5e4d4e3-789c-4209-b695-6f2c73e52461"
        }
        if (total == 136000) {
            link = "https://checkout.globalgetnet.com.ar/orders/cf65a476-4efd-456c-82ff-83e5bfc97804"
        }
        if (total == 160000) {
            link = "https://checkout.globalgetnet.com.ar/orders/c9fdf680-e666-4cba-b977-7e844b5af9b9"
        }
        if (total == 144000) {
            link = "https://checkout.globalgetnet.com.ar/orders/8167b1b1-bb6b-41d6-97f4-0bb71fc9dc6f"
        }
        if (total == 152000) {
            link = "https://checkout.globalgetnet.com.ar/orders/b0c0f81a-ac02-4890-b5c2-d17acf34f99a"
        }
        if (total == 192000) {
            link = "https://checkout.globalgetnet.com.ar/orders/c6da88dd-7df3-4825-9da6-42cfe6e05547"
        }
        if (total == 216000) {
            link = "https://checkout.globalgetnet.com.ar/orders/8eab6414-aa59-4366-8097-22688bc7f1c6"
        }
        if (total == 200000) {
            link = "https://checkout.globalgetnet.com.ar/orders/ff8d727a-de59-4d07-9d98-1b10c2973a01"
        }
        if (total == 184000) {
            link = "https://checkout.globalgetnet.com.ar/orders/ade3ce74-c6ab-4d30-810d-0f0f8768003a"
        }
        if (total == 240000) {
            link = "https://checkout.globalgetnet.com.ar/orders/d2a8b25c-a463-405f-9726-f4a135dab8ab"
        }
        if (total == 224000) {
            link = "https://checkout.globalgetnet.com.ar/orders/c713fd48-e0d4-4982-bff2-9c0f64a9301f"
        }
        if (total == 272000) {
            link = "https://checkout.globalgetnet.com.ar/orders/e2d93c6b-6ce1-4fe7-8903-5a78d8c5d4f3"
        }
        if (total == 120000) {
            link = "https://checkout.globalgetnet.com.ar/orders/3108c4cd-0077-4764-ba05-54758c8156c5"
        }
        if (total == 176000) {
            link = "https://checkout.globalgetnet.com.ar/orders/efb8d962-3539-47bf-bf4c-b6cd6df6c5e2"
        }
        if (total == 248000) {
            link = "https://checkout.globalgetnet.com.ar/orders/e5d85064-2f05-43bf-b931-d1431a513519"
        }
        if (total == 280000) {
            link = "https://checkout.globalgetnet.com.ar/orders/d0f124f2-6342-4486-8ab4-ea5f81cfa230"
        }
        if (total == 64000) {
            link = "https://checkout.globalgetnet.com.ar/orders/caaba9fd-7c0b-49d4-8233-4237f69149f9"
        }
        if (total == 273600) {
            link = "https://checkout.globalgetnet.com.ar/orders/48be3996-4ad1-4c63-8e69-c75d01647ab0"
        }

        // Recorro la lista de tickets
        for (let index = 0; index < tickets.length; index++) {
            // si el ticket actual tiene la key "total":
            if (tickets[index][1].total) {
                // redondeo su valor
                tickets[index][1].total = Math.round(tickets[index][1].total);
            }
            // si la cantidad del ticket es 0, lo elimino del diccionario:
            if (tickets[index][1].cantidad == 0) {
                // elimino la informacion de los tickets sin cantidad
                delete data.tickets[tickets[index][0]]
            } else {
                totalAPagar = totalAPagar + (tickets[index][1].cantidad * parseInt(tickets[index][1].precio))

                // le doy el valor True a "HayTickets"
                HayTickets = true
            }
        }

        if (HayTickets) {
            data.totalAPagar = Descuento != 0 ? Math.round((TotalGlobal - Descuento) + TotalGlobalDescuentoDesHabilitado) : TotalGlobal                // envio el total a pagar
            data.Token = Token                              // agrego el token a la data
            data.id_evento = id_evento                      // agrego la id del evento a la data
            data.event_name = Data.event_name               // agrego el nombre del evento a la data
            data.fecha_inicio = Data.fecha_inicio           // agrego la fecha de incio del congreso a la data
            data.API_Key = Data.pasarelaDePagos.API_Key     // agrego la API key de mercado pago a la data

            axios({
                method: 'post',
                url: BackendURL('/pagos/getnet'),
                data: data,
                headers: {
                    "Accept": "application/json"
                },
            }).then(function (response) {
                // si todo salio bien re direcciono al usuario al init point (MercadoPago)
                window.location.replace(link)
            }).catch(function (error) {
                console.log(error);
            });
        }


    }

    // onSubmit mercado pago
    // const onSubmit = data => {
    //     // lanzo la alerta de carga
    //     MySwal.fire({
    //         didOpen: () => { Swal.showLoading() }
    //     })

    //     // reviso si se selecciono alguna entrada
    //     // Declaro la variable "tickets" como una lista con las keys de "data.tickets"
    //     let tickets = Object.entries(data.tickets)
    //     let HayTickets = false
    //     // Recorro la lista de tickets
    //     for (let index = 0; index < tickets.length; index++) {
    //         // si el ticket actual tiene la key "total":
    //         if (tickets[index][1].total) {
    //             // redondeo su valor
    //             tickets[index][1].total = Math.round(tickets[index][1].total);
    //         }
    //         // si la cantidad del ticket actual es distinta de 0:
    //         if (tickets[index][1].cantidad != 0) {
    //             HayTickets = true           // le doy el valor True a "HayTickets"
    //             break           // Rompo el bucle
    //         }
    //     }

    //     // si se selecciono al menos una entrada envio la data al backend, si no muestro una alerta
    //     if (HayTickets) {
    //         data.Token = Token                              // agrego el token a la data
    //         data.id_evento = id_evento                      // agrego la id del evento a la data
    //         data.event_name = Data.event_name               // agrego el nombre del evento a la data
    //         data.fecha_inicio = Data.fecha_inicio           // agrego la fecha de incio del congreso a la data
    //         data.API_Key = Data.pasarelaDePagos.API_Key     // agrego la API key de mercado pago a la data

    //         // creo la key "descuentos" dentro de tickes:
    //         data.tickets["descuentos"] = {
    //             // si el descuento (en negativo) mas el total sin descuentos es mayor o igual a 0 devuelvo un 0 sino un 1
    //             cantidad: (-Descuento + TotalGlobalDescuentoDesHabilitado) >= 0 ? 0 : 1,
    //             // si el descuento es igual a 0, devuelvo un 0, sino devuelvo la suma del descuento(en negativo) y el total sin descuentos
    //             precio: Descuento == 0 ? 0 : (-Descuento + TotalGlobalDescuentoDesHabilitado)
    //         }

    //         console.log(data)

    //         // envio la data al backend
    //         axios({
    //             method: 'post',
    //             url: BackendURL('/pagos/checkout'),
    //             data: data,
    //             headers: {
    //                 "Accept": "application/json"
    //             },
    //         }).then(function (response) {
    //             // si todo salio bien re direcciono al usuario al init point (MercadoPago)
    //             window.location.replace(response.data.init_point)
    //         }).catch(function (error) {
    //             console.log(error);
    //         });

    //     } else {
    //         // Si no hay ninguna entrada seleccionada muestro una alerta que lo avise
    //         MySwal.fire({
    //             title: <strong> Tenés que elegir alguna entrada </strong>,
    //             html: <i> No podemos continuar si la cantidad de entradas es 0 </i>,
    //             showCloseButton: true,
    //             icon: 'error',
    //             didOpen: () => { Swal.hideLoading() }
    //         })
    //     }
    // }


    return (
        <Layout>
            <div id='ComprarEntradas'>
                <div className='header'>
                    {/* {Data ? <h1> Compra tus entradas para: {Data.event_name} </h1> : ''} */}
                    {Data ? <h1> INSCRIBIRSE A: {Data.event_name} </h1> : ''}
                </div>

                <div className='content' >
                    <form className='entradas' onSubmit={handleSubmit(onSubmit)}>

                        {Data ? Data.tickets.map((entrada_info, index) => <Entrada key={index} setValue={setValue} register={register} entrada_info={entrada_info} />) : <Skeleton variant="rounded" width='100%' height="100px" />}

                        {/* <div className='cupones'>
                            Tengo un cupon de descuento
                            <TextField
                                className='input_cupon'
                                id="outlined-required"
                                color="secondary"
                                fullWidth
                                {...register("cupon")}
                            />
                        </div> */}

                        {/* <DescuentosAPIs register={register} SetActualizar={SetActualizar} /> */}

                        <div className='total'>
                            {Descuento != 0 ? <h3> TOTAL: ARS $<del>{TotalGlobal}</del>    ${Math.round((TotalGlobal - Descuento) + TotalGlobalDescuentoDesHabilitado)} </h3> : <h3> TOTAL: ARS ${TotalGlobal} </h3>}
                        </div>


                        {Data ?
                            <>
                                {Data.info_extra ?
                                    <Button className='pagar' color="secondary" variant="contained" type='button' onClick={() => setOpen(true)}  > Continuar </Button>
                                    :
                                    <Button className='pagar' color="secondary" variant="contained" type='submit' onSubmit={handleSubmit(onSubmit)}  > Pagar </Button>
                                }
                            </>
                            :
                            <Button className='pagar' color="secondary" variant="contained" type='submit' disabled > Continuar </Button>
                        }


                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <div className='backdrop'>
                                <div className='cerrar_container'>
                                    <button className='cerrar' type='button' onClick={() => setOpen(false)}>
                                        cerrar
                                        <CloseIcon />
                                    </button>
                                </div>
                                <h1> Por favor completa este formulario antes de continuar  </h1>

                                {Data ?
                                    <div className='CustomFomrElement'>
                                        {Data.info_extra?.length != 0 ? Data.info_extra?.map((info, index) =>
                                            // console.log(info.nombre.replace(/ /g, "").replace(/,/g, ""))
                                            <FormControl sx={{ m: 1, width: '80%' }} key={index}>
                                                <TextField
                                                    color="secondary"
                                                    fullWidth
                                                    // required
                                                    size="small"
                                                    id="outlined-required-nombre"
                                                    label={info.nombre}
                                                    {...register(`info_extra.${info.nombre.replace(/ /g, "").replace(/,/g, "")}`)}
                                                />
                                            </FormControl>
                                        )

                                            : null}
                                    </div>
                                    : null}

                                {Data ? <Button className='pagar' color="secondary" variant="contained" type='submit' onSubmit={handleSubmit(onSubmit)}  > Pagar </Button> : null}

                            </div>
                        </Backdrop>
                    </form>
                </div>
            </div>
        </Layout>
    )
}