// MUI
import TextField from '@mui/material/TextField';


export default function InfoBasica({ register, EventInfo }) {
    return (
        <div className="FormSection">
            <h2> Informacion Basica </h2>
            <p>
                Asigne un nombre a su evento y dígales a los asistentes por qué deberían asistir.
                Agregue detalles que resalten lo que lo hace único.
            </p>

            <h3> Nombre del evento </h3>
            {EventInfo ?
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    defaultValue={EventInfo.event_name}
                    {...register("event_name")}
                />
                :
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    {...register("event_name")}
                />}

            <h3> Descripcion </h3>

            {EventInfo ?
                <TextField
                    required
                    id="outlined-multiline-flexible"
                    color="secondary"
                    fullWidth
                    multiline
                    maxRows={5}
                    defaultValue={EventInfo.event_description}
                    {...register("event_description")}
                />
                :
                <TextField
                    required
                    id="outlined-multiline-flexible"
                    color="secondary"
                    fullWidth
                    multiline
                    maxRows={5}
                    {...register("event_description")}
                />
            }

            <h3> Organizador </h3>
            {EventInfo ?
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    defaultValue={EventInfo.organizador}
                    {...register("organizador")}
                />
                :
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    {...register("organizador")}
                />
            }

        </div>
    )
}