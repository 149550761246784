// React
import { useForm } from "react-hook-form";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
// Config
import { BackendURL } from "../../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// componentes
import Entradas from "./EntradasTab/Entradas.jsx";
import Codigos from "./CodigosTab/Codigos.jsx";
import Configuracion from "./ConfiguracionTab/Configuracion.jsx";
// css
import "../CrearEventos.css"
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


export default function CrearEvento_2({ EventInfo, setStatus }) {
    // form hooks
    const { register, unregister, handleSubmit, watch, formState: { errors } } = useForm();
    // alerts
    const MySwal = withReactContent(Swal)
    // navigation
    const navigate = useNavigate();
    // backDrop
    const [open, setOpen] = useState(false);


    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [tickets_count, settickets_count] = useState(0);
    const [Codigos_count, setCodigos_count] = useState(0);


    const onSubmit = data => {
        // agrego el token y la id del evento a la data que se envia al backend
        data.Token = Cookies.get('Token')
        data.id_evento = Cookies.get('EventoEnCreacion_id')
        data.EstadoCreacion = 3

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/events/save/general'),
            data: data,
            headers: {
                "Accept": "application/json"
            },

        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tu usuario fue creado exitosamente!</i>,
                icon: 'success'
            })
            Cookies.set('EventoEnCreacion_estado', 3, { expires: 7 });
            // console.log(response)
            // envio al usuario al siguiente formulario
            setStatus(3)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
                <form className="CrearEvento" onSubmit={handleSubmit(onSubmit)}>
                    <div className="FormSection">

                        <h2> Tickets </h2>
                        <p>
                            Determina la cantidad, precio y fechas en que estaran disponibles los tickets de tu evento.
                        </p>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    centered
                                >
                                    <Tab label="Entradas" value="1" />
                                    {/* <Tab label="Extras" value="2" /> */}
                                    <Tab label="Codigos de promocion" value="3" />
                                    <Tab label="Configuracion" value="4" />
                                </TabList>
                            </Box>

                            <TabPanel value="1">
                                <Entradas register={register} tickets_count={tickets_count} settickets_count={settickets_count} />
                            </TabPanel>


                            <TabPanel value="3">
                                <Codigos register={register} Codigos_count={Codigos_count} setCodigos_count={setCodigos_count} />
                            </TabPanel>
                            <TabPanel value="4">
                                <Configuracion register={register} />
                            </TabPanel>
                        </TabContext>

                    </div>

                    <Button
                        className="siguiente"
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        Guardar
                    </Button>
                </form>
    )
}
