// React
import { useEffect, useState, useContext } from 'react';
// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function Dias({ diasCount, register }) {

    let dias = []

    for (let index = 0; index <= diasCount; index++) {

        dias.push(
            <Dia key={index} register={register} index={index} />
        )

    }

    return (
        <>
            {dias}
        </>
    )
}

function Dia({ register, index }) {
    const [actividadesCout, SetActividadesCout] = useState(0);
    return (


        <div className='dia'>
            <div className='title'>
                <h3> Dia {index} </h3>
                <TextField
                    color="secondary"
                    size="small"
                    fullWidth
                    required
                    id="outlined-required"
                    label="Nombre del Dia"
                    {...register(`cronograma[${index}].nombre`)}
                />
                <TextField
                    color="secondary"
                    size="small"
                    fullWidth
                    required
                    id="outlined-required"
                    label="Fecha del dia"
                    InputLabelProps={{ shrink: true }}
                    type='date'
                    {...register(`cronograma[${index}].fecha`)}
                />
            </div>

            <Actividades actividadesCout={actividadesCout} register={register} dia_index={index} />

            <Button
                onClick={() => SetActividadesCout(actividadesCout + 1)}
                type='button'
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                sx={{ m: 1, width: '50%' }}>
                Agregar actividad
            </Button>
        </div>
    )
}


function Actividades({ actividadesCout, register, dia_index }) {

    let actividades = []

    for (let index = 0; index <= actividadesCout; index++) {

        actividades.push(
            <Actividad key={index} register={register} index={index} dia_index={dia_index} />
        )

    }

    return (
        <>
            {actividades}
        </>
    )
}


function Actividad({ register, index, dia_index }) {
    register(`cronograma[${dia_index}].actividades[${index}].id`, { value: `${dia_index}_${index}` })
    register(`cronograma[${dia_index}].actividades[${index}].asistentes`, { value: [] })
    return (
        <div className='actividad'>
            <h4> Actividad {index} </h4>
            <TextField
                color="secondary"
                size="small"
                fullWidth
                required
                id="outlined-required"
                label="Nombre de la actividad"
                {...register(`cronograma[${dia_index}].actividades[${index}].nombre`)}
            />
            <TextField
                color="secondary"
                size="small"
                fullWidth
                required
                id="outlined-required"
                label="Hora incio"
                InputLabelProps={{ shrink: true }}
                type='time'
                {...register(`cronograma[${dia_index}].actividades[${index}].hora_inicio`)}
            />
            <TextField
                color="secondary"
                size="small"
                fullWidth
                required
                id="outlined-required"
                label="Hora final"
                InputLabelProps={{ shrink: true }}
                type='time'
                {...register(`cronograma[${dia_index}].actividades[${index}].hora_final`)}
            />
            <TextField
                color="secondary"
                size="small"
                fullWidth
                required
                id="outlined-required"
                label="cupo maximo"
                {...register(`cronograma[${dia_index}].actividades[${index}].cupo`)}
            />
            <div className='CheckBoxContainer'>
                <FormControlLabel
                    control={
                        <Checkbox 
                            defaultChecked 
                            color="secondary" 
                            {...register(`cronograma[${dia_index}].actividades[${index}].mostrar`)}
                        />
                    }
                    label="Mostrar a asistentes"
                    color="secondary"
                />
                <FormControlLabel
                    control={
                        <Checkbox 
                            defaultChecked 
                            color="secondary" 
                            {...register(`cronograma[${dia_index}].actividades[${index}].asistencia`)}
                        />
                    }
                    label="Incluida en la asistencia"
                />
            </div>
        </div>
    )
}