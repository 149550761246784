// React
import { useForm } from "react-hook-form";
import { useState } from "react"
// Config
import { BackendURL } from "../../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';



export default function CrearEvento_5({ EventInfo, setStatus }) {
    // form hooks
    const { register, handleSubmit, formState: { errors } } = useForm();
    // alerts
    const MySwal = withReactContent(Swal)

    // const [Campos_count, setCampos_count] = useState(0);
    const [Pasarela, SetPasarela] = useState(null);

    const onSubmit = data => {
        console.log(data)

        data.Token = Cookies.get('Token')
        data.id_evento = Cookies.get('EventoEnCreacion_id')
        data.EstadoCreacion = 6

        data.pasarelaDePagos.nombre = Pasarela

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/events/save/general'),
            data: data,
            headers: {
                "Accept": "application/json"
            },

        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tu usuario fue creado exitosamente!</i>,
                icon: 'success'
            })
            Cookies.set('EventoEnCreacion_estado', 6, { expires: 7 });

            console.log(response)

            setStatus(6)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <form className="CrearEvento" onSubmit={handleSubmit(onSubmit)}>

            <div className="FormSection pago">
                <h2> Metodo de pago </h2>
                <p>
                    Configura la pasarela de pagos para que puedas cobrar tus entradas.
                </p>

                <Button
                    className="bottonAgregar"
                    type='button'
                    variant="contained"
                    color="secondary"
                    onClick={() => SetPasarela('MercadoPago')}
                >
                    Mercado pago
                </Button>

                <Button
                    className="bottonAgregar"
                    type='button'
                    variant="contained"
                    color="secondary"
                    onClick={() => SetPasarela('PayWay')}
                >
                    PayWay
                </Button>

                {Pasarela == 'MercadoPago' ?
                    <>
                        <h3> Access Token </h3>
                        <TextField
                            required
                            id="outlined-required"
                            color="secondary"
                            fullWidth
                            // defaultValue={EventInfo.event_name}
                            {...register("pasarelaDePagos.API_Key")}
                        />
                    </>
                    :
                    null
                }



                {/* APP_USR-8764485402497241-061815-57beb20c0eff16f556f5a74fd4333be3-263465337 */}

            </div>



            <Button
                className="siguiente"
                type='submit'
                variant="contained"
                color="secondary"
                size="large"
                sx={{ m: 1, width: '50%' }}
                endIcon={<SendIcon />}>
                Guardar
            </Button>
        </form>
    )
}