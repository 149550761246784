// React
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
//qrs
import { Html5QrcodeScanner } from "html5-qrcode";
// css
import './Acreditar.css';
// context
// import { Event_Data } from '../../EventPage.jsx';
// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

export default function Acreditar({usuariodata}) {
    // informacion del evento traida del contexto
    // const [EventData, SetEventData] = useState(useContext(Event_Data))
    // creo un array con los IDs de los gerentes
    // const UsersIDs = EventData.gerentes

    // guardo la info del usuario traida del backend
    const [UserInfo, SetUserInfo] = useState();
    // informacion leida del qr
    const [ScanResult, setScanResult] = useState(null)
    // informacion leida del qr
    const [ScanDisplay, SetScanDisplay] = useState('hidden')
    // tipo de entrada
    const [TipoEntrada, SetTipoEntrada] = useState(null)
    // form
    const { register, formState: { errors }, handleSubmit } = useForm()
    // Alerts
    const MySwal = withReactContent(Swal)

    const onSubmit = data => {

        let data2 = {}

        // reviso que tipo de entrada tiene el asistente
        const entrada = usuariodata.map((usuario) => {
            if (usuario.DNI === data.DNI) {
                return usuario.tipo_entrada
            }
        })

        SetTipoEntrada(entrada[0])

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'get',
            url: `https://sisabackend.herokuapp.com/users/info_dni/${data.DNI}`,
            // url: `http://localhost:3000/users/info_dni/${data.DNI}`,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            console.log(response.data)

            SetUserInfo(response.data)

            data2 = response.data

            SetScanDisplay('visible')

            MySwal.close()

            const scanner = new Html5QrcodeScanner('reader', {
                qrbox: {
                    width: 250,
                    height: 250,
                },
                fps: 5,
            })
    
            scanner.render(success, error);
    
            function success(result) {
                scanner.clear();
    
                setScanResult(result);
    
                const data = {
                    id_qr: result,
                    id_usuario: data2._id
                }
    
                console.log(data)
    
                MySwal.fire({
                    didOpen: () => { Swal.showLoading() }
                })
                axios({
                    method: 'post',
                    url: `https://sisabackend.herokuapp.com/users/actualizar`,
                    // url: `http://localhost:3000/users/actualizar`,
                    data: data,
                    headers: {
                        "Accept": "application/json"
                    },
                }).then(function (response) {
                    console.log(response.data)
        
                    MySwal.fire({
                        title: <strong>Genail!</strong>,
                        html: <i>Usuario acreditado</i>,
                        icon: 'success',
                    })
    
                    SetUserInfo(null)
                    setScanResult(null)
                    SetScanDisplay('hidden')
    
        
                }).catch(function (error) {
                    MySwal.fire({
                        title: <strong> Algo salio mal :( </strong>,
                        html: <i>{error.response.data}</i>,
                        icon: 'error'
                    })
                    console.log(error);
                });
            }
    
            function error(err) {
                console.warn(err)
            }

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }


    return (
        <div className='Acreditar'>
            {UserInfo ?
                null
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        color="secondary"
                        size="small"
                        required
                        fullWidth
                        id="outlined-required"
                        label="DNI"
                        type='number'
                        {...register('DNI')}
                    />

                    <Button
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        Buscar usuario
                    </Button>
                </form>
            }

            <h1 className='tipo_entrada'> {TipoEntrada} </h1>

            <div id="reader" width="600px" style={{visibility: ScanDisplay}}></div>

        </div>
    );

}