// React
import { useEffect, useState, createContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// Config
import { BackendURL } from "../../Config";
// Axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie';
// componentes
import Layout from '../Layout/Layout'
import Cronograma from './Cronograma/Cronograma';
import GerentesSection from './Gerentes/Gerentes';
// css
import './EventPage.css';
// MUI
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
// icons
import TodayIcon from '@mui/icons-material/Today';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// imgs 
import LOGO_AMPROS from './imgs/logo ampros.png'
// json web token
import { useJwt } from "react-jwt";

// creo el contexto "Event_Data"
export const Event_Data = createContext();

export default function EventPage() {
    // el usuario esta logueado??
    const [IsLogued, SetIsLogued] = useState(false);
    // token traido de cookies
    const { decodedToken, isExpired } = useJwt(Cookies.get('Token'));
    // id del evento traido de los parametros de la url
    const { id_evento } = useParams();
    // data del evento traida del backend
    const [Data, SetData] = useState();
    // fechas del evento
    const [Fechas, SetFechas] = useState();
    // texto que se muestra en las fechas
    const [Fechas_texto, SetFechas_texto] = useState();
    // ubicacion del evento
    const [Ubicacion, SetUbicacion] = useState();
    // descripcion del evento
    const [event_description, Set_event_description] = useState();
    // Inscriptos al evento
    const [Inscriptos, SetInscriptos] = useState();
    // usuario inscripto
    const [EstaInscripto, SetEstaInscripto] = useState(false);

    // reviso si el usuario esta logueado
    useEffect(() => {
        if (Cookies.get('Token')) {
            SetIsLogued(true)
        }
    }, []);

    // pido la data del evento al backend
    useEffect(() => {
        axios({
            method: 'post',
            url: BackendURL('/events/info'),
            data: {
                event_id: id_evento,
            },
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            // console.log(response.data)
            // Formateo las fechas
            let fechaInicio = new Date(response.data.fecha_inicio)
            fechaInicio.setDate(fechaInicio.getDate() + 1);
            let fechaFinal = new Date(response.data.fecha_final)
            fechaFinal.setDate(fechaFinal.getDate() + 1);

            let fechas = {
                inicio: fechaInicio,
                actual: new Date(),
                fin: fechaFinal
            }

            SetFechas(fechas)

            // defino el texto que se va a mostrar en la fecha
            if (fechas.inicio === fechas.fin) {
                SetFechas_texto(fechas.inicio.toLocaleDateString())
            } else {
                // SetFechas_texto(`Del ${fechas.inicio.toLocaleDateString()} al ${fechas.fin.toLocaleDateString()}`)

                SetFechas_texto(fechas.inicio.toLocaleDateString())
            }

            // Defino el texto que se va a monstrar en la ubicacion
            if (response.data.event_location_url && response.data.event_location_name != "") {
                SetUbicacion(<a href={response.data.event_location_url}> {response.data.event_location_name} </a>)
            } else if (response.data.event_url && response.data.event_url != "") {
                SetUbicacion(<a href={response.data.event_url}> {response.data.event_url} </a>)
            } else {
                SetUbicacion(<h4> La ubicacion se publicara en breve </h4>)
            }

            SetData(response.data)
            Set_event_description(response.data.event_description.split(''))

            const inscriptos = response.data.inscriptos.map((inscripto) => inscripto.ID_usuario)
            SetInscriptos(inscriptos)

            console.log(inscriptos)
            console.log(response.data)
        }).catch(function (error) {

            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (decodedToken) {
            if (Inscriptos) {
                SetEstaInscripto(Inscriptos.includes(decodedToken.id))
            }
        }
    }, [decodedToken, Inscriptos]);


    return (
        <Layout>
            {/* Defino el Event_Data provider para servir a todo lo que este dentro de la paguina del evento con la info del mismo */}
            <Event_Data.Provider value={Data}>
                <div id='eventPage'>
                    <div className='header'>
                        <div className='imgs'>
                            <div className='imgs falsofondo'>
                                {Data ? <img src={BackendURL(`/utilities/img/${Data.event_media}`)} alt="" /> : <Skeleton variant="rectangular" width='100%' height="100%" />}
                            </div>
                            {Data ? <img src={BackendURL(`/utilities/img/${Data.event_media}`)} className='fondo' alt="" /> : <Skeleton variant="rectangular" width='100%' height="100%" />}
                        </div>
                        {/* <div className='imgs'>
                            <div className='imgs falsofondo'>
                                {Data ? <img src={`https://sisabackend.herokuapp.com/imgs/${id_evento}.png`} alt="" /> : <Skeleton variant="rectangular" width='100%' height="100%" />}
                            </div>
                            {Data ? <img src={`https://sisabackend.herokuapp.com/imgs/${id_evento}.png`} className='fondo' alt="" /> : <Skeleton variant="rectangular" width='100%' height="100%" />}
                        </div> */}
                    </div>

                    <div className='basicInfo'>
                        <div className='fecha_comprar'>

                            {Data ? <h2> {Fechas.inicio.toLocaleDateString()} </h2> : <Skeleton variant="text" sx={{ fontSize: '1rem', width: '20%' }} />}

                        </div>
                        <div className='titulo'>

                            {Data ? <h1> {Data.event_name} </h1> : <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />}

                        </div>

                        <div className='cuando_donde'>
                            <div className='cuando'>
                                <div className='title'>
                                    <TodayIcon />
                                    <h3> ¿Cuándo? </h3>
                                </div>
                                Fecha:
                                {Data ? <h4> {Fechas_texto} </h4> : <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%' }} />}

                            </div>
                            <div className='separador'></div>
                            <div className='donde'>
                                <div className='title'>
                                    <LocationOnIcon />
                                    <h3> ¿Dónde? </h3>
                                </div>

                                {/* {Data ? Ubicacion : <Skeleton variant="text" sx={{ fontSize: '4rem', width: '60%' }} />} */}

                                <h4> Plataforma moodle </h4>

                            </div>
                        </div>
                    </div>

                    {Data ?
                        decodedToken ?
                            Data.gerentes.indexOf(decodedToken.id) === -1 ?
                                null
                                : <GerentesSection UserInfo={decodedToken} />
                            : null
                        : null
                    }

                    {/* <Cronograma/> */}

                    <div className='descripcion'>
                        <div className='title'>
                            <InfoOutlinedIcon />
                            <h2> Sobre el evento: </h2>
                        </div>
                        <div>
                            {Data ?
                                // <p> {Data.event_description} </p> 
                                <p>
                                    {event_description.map((letra) => {
                                        if (letra == '~') {
                                            return <br />
                                        } else {
                                            return letra
                                        }
                                    })}
                                </p>
                                :
                                <Skeleton variant="rectangular" width='100%' height={200} />
                            }
                        </div>
                    </div>

                    {/* <div className='descripcion entrada'>
                        <div className='title'>
                            <InfoOutlinedIcon />
                            <h2> Que incluye mi entrada: </h2>
                        </div>
                        <div>
                            {Data ?
                                Data.tickets.map((Entrada, Index) => {
                                    return (
                                        <div key={Index} className='TarjetaDescripcion'>
                                            <div className='texto'>
                                                <h3> {Entrada.nombre} </h3>
                                                <p> {Entrada.Descripcion} </p>
                                            </div>
                                            {Entrada.nombre == "Entrada general" ?
                                                <div className='incluye'>
                                                    <ul>
                                                        <li>
                                                            <span> 3 Topic </span>
                                                        </li>
                                                        <li>
                                                            <span> Conferencias </span>
                                                        </li>
                                                        <li>
                                                            <span> Cena de Gala </span>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <span> Cocktail de Bienvenida </span>
                                                        </li>
                                                        <li>
                                                            <span> Mesa abierta de trabajos libres </span>
                                                        </li>
                                                        <li>
                                                            <span> Actividades culturales </span>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <span> Simposio de la industria </span>
                                                        </li>
                                                        <li>
                                                            <span> Feria de empresas </span>
                                                        </li>
                                                        <li>
                                                            <span> Actividades Pre-Congreso </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                : null}
                                        </div>
                                    )
                                })
                                :
                                <Skeleton variant="rectangular" width='100%' height={200} />
                            }
                        </div>
                    </div> */}

                    {/* <div className='descripcion descuentos'>
                        <div className='title'>
                            <InfoOutlinedIcon />
                            <h2> Descuentos: </h2>
                        </div>
                        <div>
                            <div className='TarjetaDescripcion'>
                                <div className='texto'>
                                    <h3> Descuento para afiliados a AMProS </h3>
                                    <p> Para acceder a este beneficio tenés que estar afiliado y tener tu cuota al día. </p>
                                </div>
                                <div className='descuento'>
                                    <h2> 30% </h2>
                                </div>
                            </div>

                            <div className='TarjetaDescripcion'>
                                <div className='texto'>
                                    <h3> Descuento para miembros de asociaciones organizadoras </h3>
                                    <p> Quienes estén en el registro de: APREM, APSIM, ACEP, DAr, EOL, SPM, Asociación de Psicoanalisis, Pareja, Familia y Grupo Mendoza y CPPM  podran acceder a este descuento.</p>
                                </div>
                                <div className='descuento'>
                                    <h2> 15% </h2>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='organizador'>
                        <h2 className='titulo'> Organiza: <small>{Data?.organizador}</small> </h2>
                        {/* <div className='data'>
                            <h3> AMProS </h3>
                            <div className='img'>
                                <img src={LOGO_AMPROS} alt="" />
                            </div>
                        </div> */}
                    </div>

                    <div className='macrobotoncomprar'>
                        <BotonComprar Data={Data} IsLogued={IsLogued} EstaInscripto={EstaInscripto} fechas={Fechas} />
                    </div>
                </div>
            </Event_Data.Provider>
        </Layout>
    )
}


function BotonComprar({ Data, IsLogued, EstaInscripto, fechas }) {

    const navigate_to_ = useNavigate();

    if (Data) {
        if (IsLogued) {
            if (EstaInscripto) {
                return (
                    <Button
                        disabled
                        color="secondary"
                        variant="contained"
                        sx={{ m: 1, width: '20%' }}
                    >
                        Ya estas inscripto a este evento
                    </Button>
                )
            } else {
                return (
                    <Button
                        onClick={() => navigate_to_(`/comprar/${Data._id}`)}
                        color="secondary"
                        variant="contained"
                        sx={{ m: 1, width: '20%' }}
                    >
                        {/* Comprar Entradas */}
                        Inscribirse 
                    </Button>
                )
            }
            
        } else {
            return (
                <Button
                    onClick={() => navigate_to_(`/login/comprar-${Data._id}`)}
                    color="secondary"
                    variant="contained"
                    sx={{ m: 1, width: '20%' }}
                >
                    {/* Comprar Entradas */}
                    Inscribirse 
                </Button>
            )
        }

    } else {
        return (
            <Button
                color="secondary"
                variant="contained"
                sx={{ m: 1, width: '20%' }}
                disabled
            >
                {/* Comprar Entradas */}
                Inscribirse 
            </Button>
        )
    }

}