// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// MUI
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
// css
// import './Inscriptos.css';


export default function Configuracion() {

    return (
        <div className='mailing'>
            <div className='eventos'>
                <h2> Eventos </h2>
                <div className='habilitarCreacion'>

                </div>
                <div className='fees'>

                </div>
                <h2> Usuarios </h2>
                <div className='admins'>

                </div>
                
            </div>
        </div>
    )
}