//React
import { useState } from "react";

// MUI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// icons
import CloseIcon from '@mui/icons-material/Close';

// CSS
import "./Entradas.css"

export default function Codigos({ register, Codigos_count, setCodigos_count }) {

    let tickets = []


    for (let index = 0; index <= Codigos_count; index++) {

        if (index < 5) {
            tickets.push(
                <div key={index}>
                    <Form id={index} register={register} setCodigos_count={setCodigos_count} Codigos_count={Codigos_count}/>
                    <Divider />
                </div>
            )
        }
        if (index === 5) {
            tickets.push(
                <div key={index}>
                    <h1> Solo se pueden crear 5 cupones </h1>
                </div>
            )
        }

    }

    return (
        <div className="entradas">
            {tickets}
            <Button type="button" onClick={() => setCodigos_count(Codigos_count + 1)}> Agregar </Button>
        </div>
    )
}



function Form({ id, register, setCodigos_count, Codigos_count }) {
    const [satus, setsatus] = useState('Ilimitados');

    const handleChange = (event) => {
        setsatus(event.target.value);
    };
    return (
        <div className='formulario_entradas'>
            <button  type="button" className='DeletButton'  onClick={() => setCodigos_count(Codigos_count - 1)}>
                Eliminar Codigo
                <CloseIcon/>
            </button>
            <div className="nombre">
                <h3> Nombre </h3>
                <TextField
                    required
                    id="outlined-required"
                    color="secondary"
                    fullWidth
                    label="Nombre"
                    {...register(`codigos.${id}.nombre`)}
                />
            </div>


            <h3> Descuento </h3>
            <div className="cantidad_precio">
                <TextField
                    required
                    id="outlined-start-adornment"
                    color="secondary"
                    label="% de descuento"
                    type="number"
                    {...register(`codigos.${id}.descuento`)}
                />
            </div>

            <h3> Cantidad de veses que se puede canjear el cupon </h3>
            <div className="cantidad_precio">
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={satus}
                    // label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value="Limitados">Limitados</MenuItem>
                    <MenuItem value="Ilimitados">Ilimitados</MenuItem>
                </Select>

                {satus === "Limitados" ? <TextField required id="outlined-start-adornment" color="secondary" label="cantidad" type="number" {...register(`codigos.${id}.cantidad`)} /> : null}

            </div>



        </div>
    )
}