// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
// Config
import {BackendURL} from "../../Config";
// AXIOS
import axios from 'axios';

// componentes
import Layout from "../Layout/Layout"

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DateField } from '@mui/x-date-pickers/DateField';

// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// custom css
import './Singin.css';

export default function Login() {
    const { forward_url } = useParams()

    const [showPassword, setShowPassword] = useState(false);
    const [Error, setError] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [genero, setgenero] = useState('');

    const handleChange = (event) => {
        setgenero(event.target.value);
    };

    const { register, formState: { errors }, handleSubmit, watch } = useForm()

    const MySwal = withReactContent(Swal)

    const password = watch("password");
    const password2 = watch("password2");

    // navigation
    const navigate = useNavigate();

    const onSubmit = data => {
        // reviso las contras

        if (password === password2) {
            // envio la data al backend
            MySwal.fire({
                didOpen: () => { Swal.showLoading() }
            })
            axios({
                method: 'post',
                url: BackendURL('/users/singin'),
                data: data,
                headers: {
                    "Accept": "application/json"
                },

            }).then(function (response) {
                MySwal.fire({
                    title: <strong>Genail!</strong>,
                    html: <i>Tu usuario fue creado exitosamente!</i>,
                    icon: 'success'
                })

                navigate('/login/' + forward_url)

            }).catch(function (error) {
                MySwal.fire({
                    title: <strong> Algo salio mal :( </strong>,
                    html: <i>{error.response.data}</i>,
                    icon: 'error'
                })
                console.log(error);
            });

        } else {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>Las contraseñas deven ser iguales</i>,
                icon: 'error'
            })
        }
    }

    return (
        <Layout>
            <div className="Singin_page">
                <form className="Singin_card" onSubmit={handleSubmit(onSubmit)}>
                    {/* <img src='/imgs/logos/logo_morado.svg' alt="SISA logo" /> */}
                    <h1> ¡Crea tu cuenta! </h1>

                    {/* {Error ? <p style={{ color: "#FF0000" }}> E-Mail y/o Contraseña inválida </p> : errors.email?.type === 'pattern' ? <p style={{ color: "#FF0000" }}> E-Mail y/o Contraseña inválida </p> : <p> ¡Benvenido de vuelta! </p>} */}
                    {/* {errors.email?.type === 'pattern' ? <p style={{color: "#FF0000"}}> E-Mail y/o Contraseña inválida </p> : <p> ¡Benvenido de vuelta! </p> } */}


                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <TextField
                            size="small"
                            color="secondary"
                            required
                            id="outlined-required-nombre"
                            label="Nombre completo"
                            {...register('name', {
                                required: true,
                                // pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <TextField
                            size="small"
                            color="secondary"
                            required
                            id="outlined-required 2"
                            type='number'
                            label="DNI"
                            {...register('DNI', {
                                required: true,
                                // pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }} variant="outlined">
                        <TextField
                            size="small"
                            color="secondary"
                            required
                            id="outlined-required"
                            label="E-Mail"
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <InputLabel
                            size="small"
                            color="secondary"
                            htmlFor="outlined-adornment-password">Contraseña *</InputLabel>
                        <OutlinedInput
                            size="small"
                            color="secondary"
                            required
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            {...register('password', {
                                required: true
                            })}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <InputLabel
                            size="small"
                            color="secondary"
                            htmlFor="outlined-adornment-password-2">Confirmar Contraseña *</InputLabel>
                        <OutlinedInput
                            size="small"
                            color="secondary"
                            required
                            id="outlined-adornment-password-2"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirmar contraseña"
                            {...register('password2', {
                                required: true
                            })}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>

                                <DateField 
                                    label="Fecha de nacimiento" 
                                    size="small"
                                    color="secondary"
                                    format="DD-MM-YYYY"
                                    required
                                    fullWidth
                                    {...register("fecha_nacimiento", { required: true })}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '70%' }}>
                        <InputLabel
                            size="small"
                            color="secondary"
                            id="demo-simple-select-label-genero" >Genero *</InputLabel>
                        <Select
                            size="small"
                            color="secondary"
                            {...register("gender", { required: true })}
                            required
                            labelId="demo-simple-select-label-genero"
                            id="demo-simple-select-genero"
                            value={genero}
                            label="Genero"
                            onChange={handleChange}
                        >
                            <MenuItem value={"Masculino"}>Masculino</MenuItem>
                            <MenuItem value={"Femenino"}>Femenino</MenuItem>
                            <MenuItem value={"Otro"}>Otro</MenuItem>
                        </Select>
                    </FormControl>

                    <Button
                        onClick={() => setError(!Error)}
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        Crear
                    </Button>

                </form>
            </div>
        </Layout>
    )
}