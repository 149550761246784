// React
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// Config
import {BackendURL} from "../../../../../Config.js";
// Axios
import axios from 'axios';
// css
import './CrearEntradas.css';
// context
import { Event_Data } from '../../../EventPage.jsx';
// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function CrearEntradas() {
    // informacion del evento traida del contexto
    const [EventData, SetEventData] = useState(useContext(Event_Data))

    const MySwal = withReactContent(Swal)

    const { register, formState: { errors }, handleSubmit, watch } = useForm()

    console.log(EventData)

    const entradas = EventData.tickets.map((entrada) => {
        return { label: entrada.nombre }
    })

    const onSubmit = data => {
        console.log(data)

        data._id = EventData._id

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })

        axios({
            method: 'post',
            // url: 'https://sisabackend.herokuapp.com/events/edit/entradas/codigos',
            url: BackendURL("/events/edit/entradas/codigos"),
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            setTimeout(() => {
                window.location.reload(true)
            }, "1500");

            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tus datos fueron actualizados!</i>,
                timer: 1500,
                timerProgressBar: true,
                icon: 'success'
            })

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    let codigosCangeados = []
    let codigosNoCangeados = []

    if (EventData.tickets_promocionales) {
        codigosCangeados = EventData.tickets_promocionales.filter((code) => code.canjeado == true)
        codigosNoCangeados = EventData.tickets_promocionales.filter((code) => code.canjeado == false)
    }

    

    return (
        <div id='CrearEntradas'>
            <h2> Entradas para la venta: </h2>

            <h2> Entradas promocionales: </h2>
            <div className='EntradasPromocionales'>
                <form className='promocionales' onSubmit={handleSubmit(onSubmit)}>
                    <Autocomplete
                        disablePortal
                        fullWidth
                        id="combo-box-demo"
                        options={entradas}
                        required
                        renderInput={(params) => <TextField color="secondary" {...params} label="Tipo de entrada" {...register('tipo', {
                            required: true,
                        })} />}
                    />

                    <TextField
                        // size="small"
                        fullWidth
                        color="secondary"
                        required
                        id="outlined-required-nombre"
                        label="Numero de entradas"
                        {...register('numero', {
                            required: true,
                        })}
                    />

                    <Button
                        // onClick={() => setError(!Error)}
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        // sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        Crear
                    </Button>

                </form>

                <div className='codigos'>
                    <div className='title'>
                        <span> Total: {codigosCangeados.length + codigosNoCangeados.length} </span>
                        
                        <span> Canjeados: {codigosCangeados.length} </span>

                        <span> Sin cajear: {codigosNoCangeados.length - codigosCangeados.length} </span>
                    </div>

                    <div className='codigocontainer'>
                    {/* {Event_Data ? codigosCangeados.map((code, index) => <Codigo key={index} id_evento={EventData._id} codigo={code}/> ) : null}
                    {Event_Data ? codigosNoCangeados.map((code, index) => <Codigo key={index} id_evento={EventData._id} codigo={code}/> ) : null} */}

                    {Event_Data ? EventData.tickets_promocionales?.map((code, index) => <Codigo key={index} id_evento={EventData._id} codigo={code}/> ) : null}
                    </div>
                </div>

            </div>
        </div>
    );

}


function Codigo({codigo, id_evento}){
    return(
        <div className={`codigo ${codigo.canjeado}`}>
            {codigo.canjeado ? 
                <span> <b> Codigo: </b> <strike> https://www.withsisa.com/codigo/{id_evento}/{codigo.codigo} </strike> </span> 
            : 
                <span> <b> Codigo: </b> https://www.withsisa.com/codigo/{id_evento}/{codigo.codigo} </span>
            }
            
            {codigo.canjeado_por ? <span className='name'><b> Canjeado por: </b> {codigo.canjeado_por.name} </span> : null}
        </div>
    )
}