// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// Config
import {BackendURL} from "../../../Config";
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// MUI
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
// css
// import './Inscriptos.css';


export default function Mailing() {
    // Form
    const { register, formState: { errors }, handleSubmit } = useForm()
    // Alerts
    const MySwal = withReactContent(Swal)


    const onSubmit = data => {

        console.log(data)

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/utilities/SendEmails'),
            data: data,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Estas logueado!</i>,
                icon: 'success',
                buttonsStyling: false,
            })
            console.log(response)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }


    return (
        <div className='mailing'>
            <form className="Login_card" onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    color="secondary"
                    size="small"
                    required
                    id="outlined-required"
                    label="ID del evento"
                    {...register('event_id')}
                />

                <TextField
                    color="secondary"
                    size="small"
                    required
                    id="outlined-required"
                    label="HTML"
                    {...register('HTML')}
                />



                <Button
                    // onClick={() => setError(!Error)}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ m: 1, width: '50%' }}
                    endIcon={<SendIcon />}>
                    Enviar mails
                </Button>

            </form>
        </div>
    )
}