// React
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
// Config
import { BackendURL } from "../../../Config";
// cookies
import Cookies from 'js-cookie';
// Axios
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// componentes
import Layout from "../../Layout/Layout"

// custom css
import './EliminarCuenta.css';
// import './css/header.css';
// import './css/featuredEvents.css';


export default function EliminarCuenta() {

    // froms
    const { register, formState: { errors }, handleSubmit } = useForm()
    // Alerts
    const MySwal = withReactContent(Swal)

    const onSubmit = data => {

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/MatchAle/deleteUser'),
            data: data.codigo,
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Estas logueado!</i>,
                icon: 'success',
                buttonsStyling: false,
            })

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <Layout>
            <div className="EliminarCuenta">
                <form className="Login_card" onSubmit={handleSubmit(onSubmit)}>
                    <h1> Eliminar datos de MendoCann </h1>
                    <p> Al hacer click en "Eliminar cuenta", todos los datos de la cuenta seran eliminados </p>
                    <FormControl sx={{ m: 1, width: '70%' }} variant="outlined">
                        <TextField
                            color="secondary"
                            size="small"
                            required
                            id="outlined-required"
                            label="Codigo de cuenta"
                            {...register('codigo', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                        />
                    </FormControl>

                    <Button
                        // onClick={() => setError(!Error)}
                        type='submit'
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ m: 1, width: '50%' }}
                        endIcon={<SendIcon />}>
                        Borrar cuenta
                    </Button>

                </form>
            </div>
        </Layout>
    )
}