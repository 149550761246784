// React
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useJwt } from "react-jwt";
// Config
import {BackendURL} from "../../Config";
// Axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie';
// componentes
import Layout from '../Layout/Layout'
import Insignias from './Insignias/Insignias';
import EventCard from '../generales/EventCard/EventCard';
import InfoPersonal from './InfoPersonal/InfoPersonal';
// css
import './PerfilUsuario.css';
// MUI
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';



// import info from './info.json'

export default function EventPage() {

    // navigation
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const romoveCookie = () => {
        Cookies.remove('Token')
        navigate('/login/home')
    };

    const { decodedToken, isExpired } = useJwt(Cookies.get('Token'));

    const navigate_to_ = useNavigate();

    const [userID, SetuserID] = useState();

    // reviso si el usuario esta logueado
    useEffect(() => {
        if (!Cookies.get('Token')) {
            navigate_to_('/login/perfil')
        } else {
            // console.log(Cookies.get('Token'))
        }
    }, []);

    // const [Data, SetData] = useState(info.data);
    const [Data, SetData] = useState();

    useEffect(() => {
        if (decodedToken) {
            const user_id = decodedToken.id
            SetuserID(user_id)

            // console.log(user_id)

            axios({
                method: 'get',
                url: BackendURL(`/users/info/${user_id}`),
                headers: {
                    "Accept": "application/json"
                },
            }).then(function (response) {

                console.log(response)

                SetData(response.data)
            }).catch(function (error) {

                console.log(error);
            });
        }
    }, [decodedToken]);

    return (
        <Layout>

            <div id='PerfilUsuario'>
                <div className='header'>
                    {/* <h1> {Data.nombre} </h1> */}

                    {Data ? <> <h1> {Data.nombre} </h1> <Button color="secondary" onClick={() => { setAnchorEl(null); romoveCookie(); }} > Cerrar sesión </Button> </> : <Skeleton variant="rounded" width='100%' height="50px" />}
                </div>

                {Data ? <Insignias insignias={Data.insignias}/> : null}

                

                <div className='perfil_section miseventos'>
                    <h2> Mis eventos </h2>

                    <div className='eventos'>
                        {Data ? (Data.eventos_asistidos ? Data.eventos_asistidos.map((evento, index) => <EventCard key={index} evento={evento} userID={userID} />) : null) : <> <Skeleton className='skeleton' variant="rounded" width='200px' height="250px" /> <Skeleton className='skeleton' variant="rounded" width='200px' height="250px" /> <Skeleton className='skeleton' variant="rounded" width='200px' height="250px" /> <Skeleton className='skeleton' variant="rounded" width='200px' height="250px" /></>}
                    </div>
                </div>

                {Data ?
                    <InfoPersonal Data={Data} userID={userID} />
                    : null}

            </div>

        </Layout>
    )
}
