// css
import './Insignias.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

import insigniaprueba from "./insigniasprueba/insignia general.png"



// import info from './info.json'

export default function Insignias({ insignias }) {
    return (
        <div className='perfil_section insignias'>
            <h2> Mis Insignias </h2>

            {insignias ?

                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {insignias.map((insignia, index) => <SwiperSlide key={index}> <img src={`https://sisabackend.herokuapp.com/imgs/Insignias/${insignia.actividad_id}.png`} alt="" /> </SwiperSlide>)}

                </Swiper>

                :
                <h3> Aun no tenés ninguna insignia </h3>
            }
        </div>
    )
}
