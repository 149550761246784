// React
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// Config
import { BackendURL } from "../../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// css
import "../CrearEventos.css"
import "./media.css"
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useFilePreview from "./useFilePreview";


export default function CrearEvento_3({ EventInfo, setStatus }) {
    // form hooks
    const { register, handleSubmit, watch } = useForm();
    // alerts
    const MySwal = withReactContent(Swal)
    // navigation
    const navigate = useNavigate();

    const file = watch("event_media");
    const [filePreview] = useFilePreview(file);


    const onSubmit = data => {
        console.log(data)

        data.Token = Cookies.get('Token')
        data.id_evento = Cookies.get('EventoEnCreacion_id')
        data.EstadoCreacion = 4


        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/events/save/media'),
            data: data,
            headers: {
                "Accept": "application/json",
                'Content-Type': "multipart/form-data"
            },

        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tu usuario fue creado exitosamente!</i>,
                icon: 'success'
            })
            console.log(response)

            Cookies.set('EventoEnCreacion_estado', 4, { expires: 7 });
            // console.log(response)
            // envio al usuario al siguiente formulario
            setStatus(4)


        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (

        <form className="CrearEvento" onSubmit={handleSubmit(onSubmit)}>

            <div className="FormSection media">
                <h2> Imagenes del evento </h2>
                <p>
                    Aqui podras agregar imagenes para darle personalidad a tu evento.
                </p>

                <label for="event_media" class="custom-file-upload">
                    {/* <i class="fa fa-cloud-upload"></i> Custom Upload */}
                    Agregar imagen
                    <AddOutlinedIcon />
                </label>

                <input
                    // onChange={changeHandler}
                    // onChange={onSelectFile}
                    type='file'
                    name="event_media"
                    id="event_media"
                    required
                    // ref={register("event_media")}
                    {...register("event_media")}
                />

                {/* <input type="file" name="file" id="file" ref={register("file")} /> */}
                {filePreview ? <img src={filePreview} alt="preview" className="preview" /> : null}

            </div>



            <Button
                className="siguiente"
                type='submit'
                variant="contained"
                color="secondary"
                size="large"
                sx={{ m: 1, width: '50%' }}
                endIcon={<SendIcon />}>
                Guardar
            </Button>
        </form>
    )
}