// componentes
import Campo from "./Campo";
// MUI
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

export default function CampoContainer({ register, Campos_count, setCampos_count }) {

    let tickets = []

    for (let index = 0; index <= Campos_count; index++) {

        if (index < 5) {
            tickets.push(
                <div key={index}>
                    <Campo id={index} register={register} setCampos_count={setCampos_count} Campos_count={Campos_count} />
                    <Divider />
                </div>
            )
        }
        if (index === 5) {
            tickets.push(
                <div key={index}>
                    <h1> Solo se pueden crear 5 entradas </h1>
                </div>
            )
        }

    }


    return (
        <div className="campos">
            {tickets}
            {/* <Button type="button" onClick={() => setCampos_count(Campos_count + 1)}> Agregar </Button> */}

            <Button
                className="bottonAgregar"
                type='button'
                variant="contained"
                color="secondary"
                onClick={() => setCampos_count(Campos_count + 1)}
            >
                Agregar
            </Button>
        </div>
    )
}