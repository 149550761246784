// React
import { useState, useContext } from 'react';
// Config
import { BackendURL } from "../../../../Config.js";
// MUI
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
// Axios
import axios from 'axios';
// components
import Sorteos from './Sorteos'
// context
import { Event_Data } from '../../EventPage.jsx';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// css
import './Inscriptos.css';
// MUI
import Button from '@mui/material/Button';


export default function DataTable({ columns, rows }) {
  // informacion del evento traida del contexto
  const [EventData, SetEventData] = useState(useContext(Event_Data))
  // Alerts
  const MySwal = withReactContent(Swal)

  console.log(rows)

  let data = {}

  const donwload = () => {

    data._ids = rows.map((row) => row._id)

    data.event_id = EventData._id

    MySwal.fire({
      didOpen: () => { Swal.showLoading() }
    })

    axios({
      method: 'post',
      url: BackendURL('/utilities/DownloadCsv'),
      data: data,
      headers: {
        "Accept": "application/json",
      },
      responseType: 'blob', // important

    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `Inscriptos ${EventData.event_name}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      MySwal.close()
    }).catch(function (error) {
      MySwal.fire({
        title: <strong> Algo salio mal :( </strong>,
        html: <i>{error.response.data}</i>,
        icon: 'error'
      })
      console.log(error);
    });

  }

  return (
    <>
      {rows ?
        <div className='iscriptos'>
          <div>
            <DataGrid
              rows={rows}
              columns={columns}
              // checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 50, 100]}
            />
          </div>
        </div>
        :
        <div className='iscriptos'>
          <div style={{ height: 400, width: '100%' }}>
            <Skeleton variant="rectangular" width='100%' height="100%" />
          </div>
        </div>
      }
      <Button
        onClick={() => donwload()}
        color="secondary"
        variant="contained"
        sx={{ m: 1, width: '20%' }}
      >
        descargar data como csv
      </Button>

      {/* {rows ? <Sorteos data={rows} /> : null} */}

      {/* {rows ? <TipoEntrada data={rows}/> : null} */}

    </>
  )
}