// React
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// Config
import {BackendURL} from "../../Config";
// AXIOS
import axios from 'axios';
// componentes
import Layout from "../Layout/Layout"
import EventCard from '../generales/EventCard/EventCard';
// MUI
import CloudIcon from '@mui/icons-material/Cloud';
import PeopleIcon from '@mui/icons-material/People';

import Skeleton from '@mui/material/Skeleton';
// custom css
import './Eventos.css';

export default function Eventos() {
    const [EventosPresenciales, SetEventosPresenciales] = useState(null);
    const [EventosVirtuales, SetEventosVirtuales] = useState(null);

    useEffect(() => {
        axios({
            method: 'get',
            url: BackendURL('/events/list'),
            headers: {
                "Accept": "application/json"
            },
        }).then(function (response) {

            console.log(response)

            // separo los eventos que se pueden mostrar del resto de eventos
            const eventos = response.data.filter(evento => evento.show)

            // separo a los eventos en presenciales y viruales
            const eventos_presenciales = eventos.filter(evento => evento.event_tipe === 'Presencial')
            const eventos_virtuales = eventos.filter(evento => evento.event_tipe === 'Virtual')

            SetEventosPresenciales(eventos_presenciales)
            SetEventosVirtuales(eventos_virtuales)
        }).catch(function (error) {

            console.log(error);
        });
    }, []);

    return (
        <Layout>
            <div className="Eventos">
                {/* <div id='EventosHeader'>

                </div> */}
                {/* <div className='eventSection'>
                    <div className='title'>
                        <PeopleIcon />
                        <h2>Eventos Presenciales</h2>
                    </div>

                    <div className="CardsContainer">
                        {EventosPresenciales ? EventosPresenciales.map((evento) => {
                            console.log(evento)

                            evento.ID_evento = evento._id
                            evento.nombre_evento = evento.event_name
                            return <EventCard evento={evento} />
                        })
                            : <>
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                            </>}
                    </div>

                </div> */}
                <div className='eventSection'>
                    <div className='title'>
                        <CloudIcon />
                        <h2>Eventos Virtuales</h2>
                    </div>

                    <div className="CardsContainer">
                        {EventosVirtuales ? EventosVirtuales.map((evento) => {
                            console.log(evento)

                            evento.ID_evento = evento._id
                            evento.nombre_evento = evento.event_name
                            return <EventCard evento={evento} />
                        })
                            : <>
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                                <Skeleton className='skeleton' variant="rounded" width='300px' height="250px" />
                            </>}
                    </div>
                </div>
            </div>
        </Layout>
    )
}