// React
import { useEffect, useState, useContext } from 'react';
// Axios
import axios from 'axios';
// css
import './EditarEvento.css';
// context
import { Event_Data } from '../../EventPage.jsx';
// components
import CrearEntradas from './CrearEntradas/CrearEntradas';
import Cronograma from './Cronograma/Cronograma';


import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            class= "tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EditarEvento() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div id='EditarEvento'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="container">
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label="basic tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab label="INFORMACION GENERAL" {...a11yProps(0)} />
                    <Tab label="CREAR ENTRADAS" {...a11yProps(1)} />
                    <Tab label="EDITAR ENTRADAS" {...a11yProps(2)} />
                    <Tab label="DESCUENTOS" {...a11yProps(3)} />
                    <Tab label="CRONOGRAMA" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                INFORMACION GENERAL
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {/* CREAR ENTRADAS */}
                <CrearEntradas/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                EDITAR ENTRADAS
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                DESCUENTOS
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                {/* CRONOGRAMA */}
                <Cronograma/>
            </CustomTabPanel>
        </div>
    );

}