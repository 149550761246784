// css
import "./FechaHora.css"
// MUI
import { OutlinedInput } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function FechaHora({ register, EventInfo }) {
    return (
        <div className="FormSection">
            <h2> Fecha y hora </h2>
            <p>
                Dile a los asistentes cuándo comienza y termina tu evento para que puedan hacer planes para asistir.
            </p>

            {/* FECHA/HORA INICIAL */}
            <div className='fechas'>
                <div className='fecha_2'>
                    <h4> Fecha de inicio </h4>

                    {EventInfo ?
                        <OutlinedInput
                            type='date'
                            fullWidth
                            required
                            color="secondary"
                            defaultValue={EventInfo.fecha_inicio}
                            {...register("fecha_inicio")}
                        />
                        :
                        <OutlinedInput
                            type='date'
                            fullWidth
                            required
                            color="secondary"
                            {...register("fecha_inicio")}
                        />
                    }
                </div>
                <div className='fecha_2'>
                    <h4> Hora de inicio </h4>

                    {EventInfo ?
                        <OutlinedInput
                            type='time'
                            fullWidth
                            color="secondary"
                            defaultValue={EventInfo.hora_inicio}
                            {...register("hora_inicio")}
                        />
                        :
                        <OutlinedInput
                            type='time'
                            fullWidth
                            color="secondary"
                            {...register("hora_inicio")}
                        />
                    }
                </div>
            </div>

            {/* FECHA/HORA FINAL */}
            <div className='fechas'>
                <div className='fecha_2'>
                    <h4> Fecha de finalizacion </h4>
                    {EventInfo ?
                        <OutlinedInput
                            type='date'
                            fullWidth
                            required
                            color="secondary"
                            defaultValue={EventInfo.fecha_final}
                            {...register("fecha_final")}
                        />
                        :
                        <OutlinedInput
                            type='date'
                            fullWidth
                            required
                            color="secondary"
                            {...register("fecha_final")}
                        />
                    }
                </div>
                <div className='fecha_2'>
                    <h4> Hora de finalizacion </h4>
                    {EventInfo ?
                        <OutlinedInput
                            type='time'
                            fullWidth
                            color="secondary"
                            defaultValue={EventInfo.hora_final}
                            {...register("hora_final")}
                        />
                        :
                        <OutlinedInput
                            type='time'
                            fullWidth
                            color="secondary"
                            {...register("hora_final")}
                        />
                    }

                </div>
            </div>

            {/* MOSTRAR HORAS? */}
            <div className='fechas configuracion'>
                <FormControlLabel className='fecha_2 checkbox' control={
                    <Checkbox
                        color="secondary"
                        checked={EventInfo?.fecha_config.mostrar_inicio}
                        {...register("fecha_config.mostrar_inicio")} />}
                    label="Mostrar hora de incio en la paguina web"
                />

                <FormControlLabel className='fecha_2 checkbox' control={
                    <Checkbox
                        color="secondary"
                        checked={EventInfo?.fecha_config.mostrar_final}
                        {...register("fecha_config.mostrar_final")} />}
                    label="Mostrar hora de finalizacion en la paguina web"
                />
            </div>
        </div>
    )
}