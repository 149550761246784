// React
import { useForm } from "react-hook-form";
import { useState } from "react"
// Config
import { BackendURL } from "../../../Config";
// AXIOS
import axios from 'axios';
// alerts
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// cookies
import Cookies from 'js-cookie';
// componentes
import CampoContainer from "./sections/CampoContainer";
// css
import "../CrearEventos.css"
import "./InfoExtra.css"
// MUI
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';


export default function CrearEvento_4({ EventInfo, setStatus }) {
    // form hooks
    const { register, handleSubmit, formState: { errors } } = useForm();
    // alerts
    const MySwal = withReactContent(Swal)

    const [Campos_count, setCampos_count] = useState(0);

    const onSubmit = data => {
        console.log(data)

        data.Token = Cookies.get('Token')
        data.id_evento = Cookies.get('EventoEnCreacion_id')
        data.EstadoCreacion = 5

        MySwal.fire({
            didOpen: () => { Swal.showLoading() }
        })
        axios({
            method: 'post',
            url: BackendURL('/events/save/general'),
            data: data,
            headers: {
                "Accept": "application/json"
            },

        }).then(function (response) {
            MySwal.fire({
                title: <strong>Genail!</strong>,
                html: <i>Tu usuario fue creado exitosamente!</i>,
                icon: 'success'
            })
            Cookies.set('EventoEnCreacion_estado', 5, { expires: 7 });

            console.log(response)

            setStatus(5)

        }).catch(function (error) {
            MySwal.fire({
                title: <strong> Algo salio mal :( </strong>,
                html: <i>{error.response.data}</i>,
                icon: 'error'
            })
            console.log(error);
        });
    }

    return (
        <form className="CrearEvento" onSubmit={handleSubmit(onSubmit)}>

            <div className="FormSection infoextra">
                <h2> Informacion Extra </h2>
                <p>
                    Aqui podras agregar campos de informacion que nesesites para tu evento en particular,
                    por ejemplo: si estas organizando un partido de football tal vez nesesites el talle de remera de los asistentes.
                </p>

                {/* <h3> Tipo de campo </h3> */}

                {/* <Campo  register={register} Campos_count={Campos_count} setCampos_count={setCampos_count}/> */}

                <CampoContainer register={register} Campos_count={Campos_count} setCampos_count={setCampos_count} />

            </div>



            <Button
                className="siguiente"
                type='submit'
                variant="contained"
                color="secondary"
                size="large"
                sx={{ m: 1, width: '50%' }}
                endIcon={<SendIcon />}>
                Guardar
            </Button>
        </form>
    )
}